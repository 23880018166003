@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/*テキスト内で使うリンクカラー*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Teko:wght@300;400;500;600&display=swap");
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  background-color: #e6ecf5; }
  .breadcrumb .commonInner > span {
    display: inline-block;
    position: relative;
    color: #6f99d9; }
  .breadcrumb .commonInner a {
    color: #6f99d9; }

.pageTitleWrap .pageTitleEn {
  color: #17489c;
  font-family: 'Teko', sans-serif;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase; }

.pageTitleWrap .pageTitle {
  color: #17489c;
  font-weight: 400; }

h1.title {
  font-weight: bold;
  color: #17489c;
  position: relative;
  line-height: 1.3; }
  h1.title:before {
    content: "";
    background: #e6ecf5;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 2px; }
  h1.title:after {
    content: "";
    background: #17489c;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 2px; }

h1.title2 {
  font-weight: bold;
  color: #17489c;
  position: relative;
  line-height: 1.3; }
  h1.title2:after {
    content: "";
    display: block;
    background-color: #17489c;
    position: absolute; }

.productsList .productsItem {
  box-shadow: 0px 6px 6px 4px rgba(23, 72, 156, 0.1); }
  .productsList .productsItem figure {
    margin: 0; }
  .productsList .productsItem .productsItemText {
    position: relative;
    color: #17489c;
    background-color: #fff; }
    .productsList .productsItem .productsItemText .num {
      font-family: 'Teko', sans-serif;
      line-height: 1;
      font-weight: 300; }
    .productsList .productsItem .productsItemText .title {
      font-weight: bold;
      line-height: 1.3;
      margin-top: 0; }
    .productsList .productsItem .productsItemText .img img {
      max-width: 126px; }
    .productsList .productsItem .productsItemText .viewmore a {
      font-family: 'Teko', sans-serif;
      color: #17489c;
      position: relative;
      display: inline-block;
      line-height: 1; }

.contWhiteBox {
  background: white;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 100%); }
  .contWhiteBox > *:first-child {
    margin-top: 0; }

.commonDL dl {
  border-bottom: 1px solid #dde2eb; }

.historyWrap {
  background-color: #eef1f6; }
  .historyWrap dl {
    border-left: 1px solid #dde2eb; }
    .historyWrap dl dt {
      font-weight: bold;
      color: #17489c;
      position: relative; }

.gMap iframe {
  width: 100%; }

.productList .productListItem a {
  display: block; }

.productList .productListItem figure {
  box-shadow: 0px 6px 6px 4px rgba(23, 72, 156, 0.1);
  margin: 0; }

.productList .productListItem .textWrap .title {
  margin: 0;
  color: #17489c;
  font-weight: bold;
  position: relative;
  line-height: 1.5; }

.productDetailMain .productDetailMainText {
  position: relative; }
  .productDetailMain .productDetailMainText .tag span {
    line-height: 1.3;
    display: inline-block;
    font-weight: bold;
    border: 2px solid #17489c;
    color: #17489c;
    position: relative;
    text-align: center; }
    .productDetailMain .productDetailMainText .tag span + span {
      margin-left: 3px; }
  .productDetailMain .productDetailMainText .mainTitle {
    color: #17489c;
    font-weight: bold;
    line-height: 1.3; }
    .productDetailMain .productDetailMainText .mainTitle img {
      max-width: 220px; }

.productDetailMain figure {
  box-shadow: 0px 6px 6px 4px rgba(23, 72, 156, 0.1);
  margin: 0; }

.anchorList {
  background-color: #e6ebf5;
  display: flex;
  flex-wrap: wrap; }
  .anchorList a {
    color: #17489c;
    font-weight: bold;
    display: inline-block;
    position: relative; }

.meritBox {
  box-shadow: 0px 8px 6px 2px rgba(23, 72, 156, 0.1);
  position: relative; }
  .meritBox .meritTitle {
    color: #17489c;
    position: absolute;
    font-weight: normal;
    letter-spacing: .1em; }
  .meritBox .meritBoxList .meritBoxItem h4 {
    font-weight: bold;
    color: #17489c; }
  .meritBox .meritBoxList .meritBoxItem .textWrap figure {
    text-align: center;
    margin: 0; }

.surunaraArrow {
  font-weight: bold;
  background: url(../images/second/surunara_arrow.png) no-repeat center bottom;
  text-align: center; }

.numTitle {
  display: flex;
  align-items: center; }
  .numTitle span {
    display: inline-block;
    font-family: 'Teko', sans-serif;
    font-weight: 600;
    color: #17489c;
    line-height: 1;
    margin-top: 5px;
    flex: none; }
  .numTitle h3 {
    font-weight: normal;
    border-left: 2px solid #e6ecf5; }

.viewmore a {
  font-family: 'Teko', sans-serif;
  color: #17489c;
  position: relative;
  display: inline-block;
  line-height: 1;
  font-weight: 500; }

.shadowBox {
  box-shadow: 0px 8px 6px 2px rgba(23, 72, 156, 0.1); }
  .shadowBox .blueTitle {
    background-color: #17489c;
    color: #fff; }

.qaBox dl dt {
  font-weight: bold;
  color: #17489c; }

.wp-pagenavi {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1; }
  .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #17489c;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    font-family: 'Teko', sans-serif;
    padding-top: 3px; }
  .wp-pagenavi .extend:hover {
    background: none !important;
    color: #17489c !important; }
  .wp-pagenavi span.current {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    position: relative;
    background-color: #17489c;
    color: #fff;
    font-family: 'Teko', sans-serif;
    padding-top: 3px; }
  .wp-pagenavi .previouspostslink, .wp-pagenavi .nextpostslink {
    color: #17489c;
    border: none;
    position: relative; }
    .wp-pagenavi .previouspostslink:hover, .wp-pagenavi .nextpostslink:hover {
      background: none !important;
      opacity: .7; }
  .wp-pagenavi .previouspostslink:before {
    content: "";
    box-sizing: border-box;
    width: 3px;
    height: 3px;
    border: 3px solid transparent;
    border-top: 3px solid #17489c;
    border-left: 3px solid #17489c;
    position: absolute;
    transform: rotate(-45deg); }
  .wp-pagenavi .nextpostslink:before {
    content: "";
    box-sizing: border-box;
    width: 3px;
    height: 3px;
    border: 3px solid transparent;
    border-bottom: 3px solid #17489c;
    border-right: 3px solid #17489c;
    position: absolute;
    transform: rotate(-45deg); }

.postYmd {
  display: flex;
  align-items: center; }
  .postYmd .ymd {
    line-height: 1;
    font-family: 'Teko', sans-serif;
    padding-top: 3px; }
  .postYmd .tag {
    display: inline-block;
    text-align: center;
    color: #17489c;
    border-radius: 20px;
    background-color: #e6ebf5;
    line-height: 1.3;
    margin-left: 20px; }

.contact_wrap input,
.contact_wrap button,
.contact_wrap select,
.contact_wrap textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  background-color: #fff; }

.contact_wrap textarea {
  resize: vertical; }

.contact_wrap input[type='checkbox'],
.contact_wrap input[type='radio'] {
  display: none; }

.contact_wrap input[type='submit'],
.contact_wrap input[type='button'],
.contact_wrap label,
.contact_wrap button,
.contact_wrap select {
  cursor: pointer; }

.contact_wrap select::-ms-expand {
  display: none; }

.contact_wrap input, .contact_wrap textarea {
  border: 1px solid #D1D1D1;
  width: 100%; }

.contact_wrap input::placeholder {
  color: #D1D1D1; }

.contact_wrap select {
  border: 1px solid #D1D1D1;
  padding: 10px;
  background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
  padding-right: 30px; }
  .contact_wrap select::placeholder {
    color: #D1D1D1; }

.contact_wrap textarea {
  margin: 10px 0 0;
  width: 100%;
  height: 10em; }

.contact_wrap > dl > dt {
  font-weight: bold; }
  .contact_wrap > dl > dt .hissu {
    background-color: red;
    color: #fff;
    font-size: 12px;
    padding: 5px 5px;
    line-height: 1;
    display: inline-block;
    margin-left: 10px; }

.contact_wrap > dl > dd .attention {
  display: block;
  font-size: 12px;
  margin-top: 5px; }

.contact_wrap > dl.uploadItem > dd {
  position: relative; }
  .contact_wrap > dl.uploadItem > dd .mwform-file-delete {
    position: absolute;
    transform: translateY(-50%); }

.contact_wrap .btn_wrap {
  text-align: center; }
  .contact_wrap .btn_wrap .commonBtn {
    background-color: #fff;
    border-radius: 30px; }

.contact_wrap .agreeBox {
  margin-top: 20px; }
  .contact_wrap .agreeBox .agreeBoxNotes {
    margin-top: 0;
    margin-bottom: 20px; }
    .contact_wrap .agreeBox .agreeBoxNotes a {
      color: #000000;
      text-decoration: underline; }
      .contact_wrap .agreeBox .agreeBoxNotes a:hover {
        text-decoration: none; }
  .contact_wrap .agreeBox .agreeBoxCheck {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    .contact_wrap .agreeBox .agreeBoxCheck label {
      font-weight: bold; }
      .contact_wrap .agreeBox .agreeBoxCheck label input[type="checkbox"] {
        display: inline-block;
        width: 19px;
        height: 19px;
        border: 1px solid #000;
        margin-right: 10px;
        background: #fff;
        padding: 0;
        transform: translateY(3px); }
        .contact_wrap .agreeBox .agreeBoxCheck label input[type="checkbox"]:checked:before {
          opacity: 1; }
        .contact_wrap .agreeBox .agreeBoxCheck label input[type="checkbox"]:before {
          border-right: 3px solid #17489c;
          border-bottom: 3px solid #17489c;
          content: '';
          display: block;
          height: 9px;
          left: 5px;
          margin-top: -7px;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: rotate(45deg);
          width: 5px; }
  .contact_wrap .agreeBox .error {
    display: block;
    width: 100%;
    text-align: center; }

.tabLinkBtn {
  display: flex;
  justify-content: center; }
  .tabLinkBtn li.now a span {
    border-bottom-color: #17489c;
    color: #17489c;
    font-weight: bold; }
  .tabLinkBtn li a {
    display: block; }
    .tabLinkBtn li a span {
      border-bottom: 3px solid transparent;
      display: inline-block;
      text-align: center;
      transition: .3s border; }

.list-group .list-group-item::before {
  content: none; }

#wpmem_login_form .link-text a {
  color: #337ab7; }

.button_div .buttons {
  background-color: #17489c;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  padding: 6px 12px; }

.wpmemCustomWrap p {
  margin-bottom: 20px; }

.wpmemCustomWrap a {
  color: #6f99d9; }

.wpmemCustomWrap ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 10px; }

ol.hangingListAb {
  list-style-type: lower-alpha;
  list-style-position: inside; }
  ol.hangingListAb li::before {
    content: none; }
  ol.hangingListAb li::marker {
    font-weight: bold;
    color: #17489c;
    font-family: 'Teko', sans-serif;
    font-weight: 600; }

.singleContents .has-inline-color.has-luminous-vivid-orange-color {
  color: #17489c !important; }

.singleContents .inner > *:first-child {
  margin-top: 0; }

.singleContents p:first-child {
  margin-top: 0; }

.singleContents h2 {
  font-weight: bold;
  position: relative;
  border-bottom: 2px solid #e6ecf5; }
  .singleContents h2:after {
    content: "";
    display: block;
    background-color: #17489c;
    position: absolute;
    border-radius: 50%; }

.singleContents h3 {
  font-weight: 400;
  border-left: 2px solid #e6ecf5; }

.singleContents h4 {
  font-weight: bold;
  color: #17489c; }

.singleContents ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0; }
  .singleContents ol li {
    position: relative; }
    .singleContents ol li:before {
      counter-increment: item;
      content: counter(item);
      font-weight: bold;
      color: #17489c;
      font-family: 'Teko', sans-serif;
      font-weight: 600; }
    .singleContents ol li:not(:first-of-type) {
      margin-top: 10px; }

.singleContents ol.hangingListAb {
  list-style-type: lower-alpha;
  list-style-position: inside; }
  .singleContents ol.hangingListAb li::before {
    content: none; }
  .singleContents ol.hangingListAb li::marker {
    font-weight: bold;
    color: #17489c;
    font-family: 'Teko', sans-serif;
    font-weight: 600; }

.singleContents p + .wp-block-gallery {
  margin-top: 10px; }

.singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
  display: inline-block;
  text-align: center;
  position: relative;
  background-color: #fff;
  border-radius: 30px;
  color: #17489c;
  box-shadow: 0px 3px 6px 4px rgba(23, 72, 156, 0.1); }

.singleContents .wp-block-button .wp-block-button__link {
  color: #17489c;
  background-color: #fff; }

.singleContents .wp-block-button.is-style-outline .wp-block-button__link {
  background-color: #fff; }

.singleContents .wp-block-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  border-top: 1px solid #17489c;
  border-left: 1px solid #17489c; }
  .singleContents .wp-block-table thead {
    border-bottom: none; }
  .singleContents .wp-block-table tr th {
    border: none;
    font-weight: bold;
    border-bottom: 1px solid #17489c;
    border-right: 1px solid #17489c;
    background-color: #17489c;
    color: #fff;
    text-align: left; }
    .singleContents .wp-block-table tr th.sub {
      background-color: #d1e2ff;
      color: #000000; }
    .singleContents .wp-block-table tr th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .singleContents .wp-block-table tr td {
    border: none;
    border-bottom: 1px solid #17489c;
    border-right: 1px solid #17489c;
    word-break: break-all; }
    .singleContents .wp-block-table tr td.bdb {
      border-bottom: 1px solid #dde2eb; }
    .singleContents .wp-block-table tr td.bdr {
      border-right: 1px solid #dde2eb; }
    .singleContents .wp-block-table tr td.sub {
      background-color: #d1e2ff;
      color: #000000; }
    .singleContents .wp-block-table tr td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }
  .singleContents .wp-block-table.customTable th {
    background-color: #e6ebf5 !important;
    color: #17489c !important; }

.singleContents .wp-block-columns .wp-block-image {
  margin-bottom: 10px; }

.singleContents .wp-block-media-text .wp-block-media-text__content p {
  margin-top: 0; }

.singleContents .colorBox {
  background-color: #dadee5; }
  .singleContents .colorBox .cont p {
    margin: 0; }

h2, h3, h4 {
  line-height: 1.3; }

h4 + p {
  margin-top: 15px; }

h2.title {
  font-weight: bold;
  position: relative;
  border-bottom: 2px solid #e6ecf5; }
  h2.title:after {
    content: "";
    display: block;
    background-color: #17489c;
    position: absolute;
    border-radius: 50%; }

h2.title2 {
  font-weight: bold;
  color: #17489c;
  position: relative; }
  h2.title2:after {
    content: "";
    display: block;
    background-color: #17489c;
    position: absolute; }

h3.title {
  font-weight: 400;
  border-left: 2px solid #e6ecf5; }

h4.title {
  font-weight: bold;
  color: #17489c; }

ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0; }
  ol li {
    position: relative; }
    ol li:before {
      counter-increment: item;
      content: counter(item);
      font-weight: bold;
      color: #17489c;
      font-family: 'Teko', sans-serif;
      font-weight: 600; }
    ol li:not(:first-of-type) {
      margin-top: 10px; }

a.icon {
  display: inline-block;
  position: relative;
  padding-right: 25px; }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: .2em; }
  a.icon[href$=pdf]:after {
    background-image: url(../images/common/icon_pdf.svg); }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/common/icon_word.svg); }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/common/icon_excel.svg); }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0; }
    a.icon.front:after {
      left: 0px; }

.iconBlank {
  display: inline-block;
  width: 17px;
  height: 13px;
  background: url("../images/common/icon_blank.svg") no-repeat 0 0;
  background-size: cover;
  vertical-align: middle;
  margin-left: 7px;
  position: relative;
  transform: translateY(-3px); }

.commonBtn {
  display: inline-block;
  text-align: center;
  position: relative;
  background-color: #fff;
  border-radius: 30px;
  color: #17489c;
  box-shadow: 0px 3px 6px 4px rgba(23, 72, 156, 0.1); }
  .commonBtn.gradeBtn {
    background: linear-gradient(90deg, #17489c 0%, #5f99fe 50%, #5f99fe 100%);
    background-size: 200% 100%;
    color: #fff; }
  .commonBtn.taikenBtn {
    background: url(../images/second/special_btn_bg.png) no-repeat left center #17489c;
    background-size: cover;
    color: #fff;
    font-weight: normal;
    position: relative;
    overflow: hidden; }
    .commonBtn.taikenBtn img {
      vertical-align: middle; }

.imgLine2 li {
  text-align: center; }
  .imgLine2 li img {
    max-width: 100%; }

.imgLine2 .cap {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 10px; }

.imgLine3 li {
  text-align: center; }
  .imgLine3 li img {
    max-width: 100%; }

.imgLine3 .cap {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 10px; }

.imgInP .img .cap {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 10px; }

.twoCol .cap {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 10px; }

.colorBox {
  background-color: #dadee5; }
  .colorBox .cont p {
    margin: 0; }

.commonTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  border-top: 1px solid #17489c;
  border-left: 1px solid #17489c; }
  .commonTable .notes {
    font-size: 13px; }
  .commonTable th {
    font-weight: bold;
    border-bottom: 1px solid #17489c;
    border-right: 1px solid #17489c;
    background-color: #17489c;
    color: #fff;
    text-align: left; }
    .commonTable th.sub {
      background-color: #d1e2ff;
      color: #000000; }
    .commonTable th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .commonTable td {
    border-bottom: 1px solid #17489c;
    border-right: 1px solid #17489c;
    word-break: break-all; }
    .commonTable td.bdb {
      border-bottom: 1px solid #dde2eb; }
    .commonTable td.bdr {
      border-right: 1px solid #dde2eb; }
    .commonTable td.sub {
      background-color: #d1e2ff;
      color: #000000; }
    .commonTable td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }

.scrollTable {
  overflow: auto; }

.scrollText + .scrollTable {
  margin-top: 0; }

.iframeBox {
  position: relative;
  padding-bottom: 56.25%; }
  .iframeBox iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

/* common class */
.pointerNone {
  cursor: default;
  text-decoration: none; }

.taC {
  text-align: center !important; }

.taL {
  text-align: left !important; }

.taR {
  text-align: right !important; }

.Center {
  margin: 0 auto !important; }

.ovh {
  overflow: hidden !important; }

.mw100 {
  max-width: 100% !important;
  width: auto !important; }

.w100p {
  width: 100% !important; }

.mB0 {
  margin-bottom: 0 !important; }

.mB5 {
  margin-bottom: 5px !important; }

.mB10 {
  margin-bottom: 10px !important; }

.mB20 {
  margin-bottom: 20px !important; }

.mB30 {
  margin-bottom: 30px !important; }

.mB40 {
  margin-bottom: 40px !important; }

.mB50 {
  margin-bottom: 50px !important; }

.mB60 {
  margin-bottom: 60px !important; }

.mB70 {
  margin-bottom: 70px !important; }

.mB80 {
  margin-bottom: 80px !important; }

.mT0 {
  margin-top: 0 !important; }

.mT5 {
  margin-top: 5px !important; }

.mT10 {
  margin-top: 10px !important; }

.mT20 {
  margin-top: 20px !important; }

.mT30 {
  margin-top: 30px !important; }

.mT40 {
  margin-top: 40px !important; }

.mT50 {
  margin-top: 50px !important; }

.mT60 {
  margin-top: 60px !important; }

.mT70 {
  margin-top: 70px !important; }

.mT80 {
  margin-top: 80px !important; }

.mL0 {
  margin-left: 0px !important; }

.mL5 {
  margin-left: 5px !important; }

.mL10 {
  margin-left: 10px !important; }

.mL20 {
  margin-left: 20px !important; }

.mL30 {
  margin-left: 30px !important; }

.mL40 {
  margin-left: 40px !important; }

.mL50 {
  margin-left: 50px !important; }

.mR0 {
  margin-right: 0px !important; }

.mR5 {
  margin-right: 5px !important; }

.mR10 {
  margin-right: 10px !important; }

.mR20 {
  margin-right: 20px !important; }

.mR30 {
  margin-right: 30px !important; }

.mR40 {
  margin-right: 40px !important; }

.mR50 {
  margin-right: 50px !important; }

.size12 {
  font-size: 12px !important; }

.size14 {
  font-size: 14px !important; }

.size16 {
  font-size: 16px !important; }

.size18 {
  font-size: 18px !important; }

.size20 {
  font-size: 20px !important; }

.red {
  color: #de0000 !important; }

.blue {
  color: #8b99ff !important; }

.gray {
  color: #666 !important; }

.bold {
  font-weight: bold !important; }

.underline {
  text-decoration: underline !important; }

.green {
  color: #5cc49c !important; }

.pink {
  color: #ff9191 !important; }

.bdb {
  border-bottom: 2px solid #004aa4; }

.fontL {
  font-size: 1.2em; }

.fontS {
  font-size: .8em; }

.floatL {
  float: left; }

.floatR {
  float: right; }

.preBox {
  background: #eff0f4;
  border: 1px solid #17489c;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.8; }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto; }

@media screen and (max-width: 1000px) {
  main {
    background: url(../images/second/second_cont_bg_sp.png) no-repeat top center;
    background-size: 100% auto; }
  .breadcrumb {
    padding: 10px 0px;
    margin-top: 60px; }
    .breadcrumb .commonInner > span {
      font-size: 11px; }
      .breadcrumb .commonInner > span:not(:last-of-type) {
        margin-right: 30px; }
        .breadcrumb .commonInner > span:not(:last-of-type):after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 1px;
          background-color: #6f99d9;
          position: absolute;
          top: 10px;
          right: -21px; }
  .pageTitleWrap {
    height: 165px;
    display: flex;
    align-items: center; }
    .pageTitleWrap .pageTitleInner {
      padding: 0 20px; }
    .pageTitleWrap .pageTitleEn {
      font-size: 36px; }
    .pageTitleWrap .pageTitle {
      font-size: 14px; }
  h1.title + * {
    margin-top: 26px; }
  h1.title {
    font-size: 20px;
    padding-bottom: 17px; }
  h1.title2 {
    font-size: 20px;
    padding-left: 30px; }
    h1.title2:after {
      width: 18px;
      height: 3px;
      top: 13px;
      left: 0; }
  .productsList {
    margin-top: 40px; }
    .productsList .productsItem + .productsItem {
      margin-top: 20px; }
    .productsList .productsItem figure img {
      object-fit: cover;
      width: 100%;
      height: 200px; }
    .productsList .productsItem .productsItemText {
      padding: 20px 20px 30px 20px; }
      .productsList .productsItem .productsItemText .num {
        font-size: 36px; }
      .productsList .productsItem .productsItemText .title {
        font-size: 20px;
        margin-top: 15px; }
      .productsList .productsItem .productsItemText .text {
        margin-top: 20px; }
      .productsList .productsItem .productsItemText .img {
        margin-top: 20px; }
      .productsList .productsItem .productsItemText .viewmore {
        text-align: right;
        margin-top: 20px; }
        .productsList .productsItem .productsItemText .viewmore a {
          font-size: 15px;
          padding-bottom: 10px;
          padding-right: 35px; }
          .productsList .productsItem .productsItemText .viewmore a:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: #17489c;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(1, 1);
            transform-origin: right top;
            transition: transform .3s; }
  .contWhiteBox {
    padding: 40px 15px 0; }
  * + .contWhiteBox {
    margin-top: 30px; }
  .commonDL {
    margin-top: 10px; }
    .commonDL dl {
      padding: 15px 0;
      display: flex; }
      .commonDL dl dt {
        width: 25%; }
      .commonDL dl dd {
        width: 75%;
        padding-left: 20px; }
  .historyWrap {
    margin-top: 30px;
    padding: 30px 20px; }
    .historyWrap dl {
      padding: 15px 0; }
      .historyWrap dl dt {
        padding-left: 20px; }
        .historyWrap dl dt:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 2px;
          background-color: #17489c;
          position: absolute;
          left: 0;
          top: 12px; }
      .historyWrap dl dd {
        padding-left: 20px;
        margin-top: 5px; }
  .gMap {
    margin-top: 30px; }
    .gMap iframe {
      height: 250px; }
  .productList {
    margin-top: 30px; }
    .productList .productListItem + .productListItem {
      margin-top: 30px; }
    .productList .productListItem .textWrap {
      margin-top: 15px;
      padding-left: 15px; }
      .productList .productListItem .textWrap .title {
        font-size: 18px;
        padding-right: 40px; }
        .productList .productListItem .textWrap .title:after {
          content: "";
          display: inline-block;
          background: url(../images/common/icon_arrow.svg) no-repeat;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 15px;
          bottom: 5px;
          transition: .3s; }
  .productDetailMain .productDetailMainText {
    text-align: center; }
    .productDetailMain .productDetailMainText .tag {
      margin-bottom: 20px;
      text-align: left; }
      .productDetailMain .productDetailMainText .tag span {
        font-size: 12px;
        padding: 3px 30px; }
        .productDetailMain .productDetailMainText .tag span:before {
          content: "";
          display: inline-block;
          background-color: #17489c;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          top: 4px;
          left: 4px; }
    .productDetailMain .productDetailMainText .mainTitle {
      font-size: 36px; }
      .productDetailMain .productDetailMainText .mainTitle + p {
        font-size: 16px;
        color: #9ea4ad;
        margin-top: 20px; }
  .productDetailMain figure {
    margin-top: 30px; }
  .anchorList {
    padding: 15px 10px;
    margin-top: 40px;
    justify-content: space-between; }
    .anchorList a {
      font-size: 14px;
      padding-right: 20px;
      margin: 5px 13px;
      width: calc(50% - 30px); }
      .anchorList a:after {
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 2px #17489c;
        border-right: solid 2px #17489c;
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 5px);
        right: 0px;
        transition: .3s; }
  .meritBox {
    padding: 40px 20px 30px; }
    .meritBox .meritTitle {
      font-size: 20px;
      padding-left: 30px;
      top: -0.5em;
      left: 15px; }
      .meritBox .meritTitle span {
        font-family: 'Teko', sans-serif;
        font-size: 16px;
        letter-spacing: normal; }
      .meritBox .meritTitle:before {
        content: "";
        display: inline-block;
        background-color: #17489c;
        width: 18px;
        height: 2px;
        position: absolute;
        top: 14px;
        left: 0; }
    .meritBox .meritBoxList .meritBoxItem {
      position: relative;
      padding-top: 90px; }
      .meritBox .meritBoxList .meritBoxItem + .meritBoxItem {
        margin-top: 20px;
        padding-top: 110px;
        border-top: 2px solid #e6ecf5; }
        .meritBox .meritBoxList .meritBoxItem + .meritBoxItem .textWrap figure {
          top: 20px; }
      .meritBox .meritBoxList .meritBoxItem h4 {
        font-size: 16px;
        text-align: center; }
      .meritBox .meritBoxList .meritBoxItem .textWrap p {
        margin-top: 10px; }
      .meritBox .meritBoxList .meritBoxItem .textWrap figure {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        text-align: center; }
        .meritBox .meritBoxList .meritBoxItem .textWrap figure img {
          max-height: 50px; }
  .surunaraArrow {
    font-size: 20px;
    margin-top: 40px;
    padding-bottom: 40px; }
  .numTitle {
    margin-top: 30px; }
    .numTitle + p {
      margin-top: 15px; }
    .numTitle span {
      font-size: 20px;
      padding-right: 15px; }
    .numTitle h3 {
      font-size: 18px;
      padding-left: 15px; }
  .viewmore {
    text-align: right;
    margin-top: 10px; }
    .viewmore a {
      font-size: 15px;
      padding-bottom: 10px;
      padding-right: 35px; }
      .viewmore a:before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: #17489c;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(1, 1);
        transform-origin: right top;
        transition: transform .3s; }
  .shadowBox {
    margin-top: 30px; }
    .shadowBox .cont {
      padding: 20px 20px; }
  .btnTwoCol > div + div {
    margin-top: 20px; }
  .qaBox {
    margin-top: 30px; }
    .qaBox dl + dl {
      margin-top: 30px; }
    .qaBox dl dt {
      font-size: 15px; }
    .qaBox dl dd {
      margin-top: 15px; }
  .wp-pagenavi {
    margin-top: 40px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      min-width: 30px;
      min-height: 30px;
      margin: 0 5px; }
    .wp-pagenavi .extend:hover {
      background: none !important;
      color: #17489c !important; }
    .wp-pagenavi span.current {
      min-width: 30px;
      min-height: 30px;
      margin: 0 5px; }
    .wp-pagenavi .previouspostslink:before {
      top: calc(50% - 2px);
      left: 50%; }
    .wp-pagenavi .nextpostslink:before {
      top: calc(50% - 2px);
      right: 50%; }
  .postYmd {
    margin-top: 10px;
    padding-left: 38px; }
    .postYmd .ymd {
      display: inline-block;
      vertical-align: middle; }
      .postYmd .ymd .year {
        font-size: 13px; }
      .postYmd .ymd .monthday {
        font-size: 19px; }
    .postYmd .tag {
      font-size: 12px;
      padding: 5px 20px;
      margin-left: 15px;
      vertical-align: middle; }
  .contact_wrap input {
    padding: 10px 10px; }
    .contact_wrap input.sizeS {
      width: 30%; }
    .contact_wrap input.sizeM {
      width: 50%; }
    .contact_wrap input::placeholder {
      color: #D1D1D1; }
  .contact_wrap select.sizeS {
    width: 30%; }
  .contact_wrap select.sizeM {
    width: 50%; }
  .contact_wrap select::placeholder {
    color: #D1D1D1; }
  .contact_wrap textarea {
    padding: 10px; }
  .contact_wrap > dl:not(:first-of-type) {
    margin-top: 20px; }
  .contact_wrap > dl > dt {
    margin-bottom: 5px; }
    .contact_wrap > dl > dt .hissu {
      background-color: red;
      color: #fff;
      font-size: 12px;
      padding: 5px 5px;
      line-height: 1;
      display: inline-block;
      margin-left: 10px; }
  .contact_wrap > dl > dd .attention {
    display: block;
    font-size: 12px;
    margin-top: 5px; }
  .contact_wrap > dl > dd > dl:not(:first-of-type) {
    margin-top: 10px; }
  .contact_wrap > dl > dd > dl > dt {
    margin-bottom: 5px; }
  .contact_wrap > dl.uploadItem > dd {
    position: relative; }
    .contact_wrap > dl.uploadItem > dd .mwform-file-delete {
      position: absolute;
      transform: translateY(-50%);
      top: calc(50% - 10px);
      right: 10px; }
  .contact_wrap .btn_wrap {
    margin-top: 30px; }
    .contact_wrap .btn_wrap .commonBtn + .commonBtn {
      margin-top: 20px; }
    .contact_wrap .btn_wrap .commonBtn.back {
      width: 50%; }
  .contact_wrap .agreeBox .agreeBoxNotes a {
    color: #000000;
    text-decoration: underline; }
    .contact_wrap .agreeBox .agreeBoxNotes a:hover {
      text-decoration: none; }
  * + .tabLinkBtn {
    margin-top: 30px; }
  .tabLinkBtn {
    align-items: center;
    width: 100%;
    margin-bottom: 30px; }
    .tabLinkBtn li {
      line-height: 1.4; }
      .tabLinkBtn li:not(:last-child) {
        margin-right: 8px; }
      .tabLinkBtn li a {
        text-align: center; }
        .tabLinkBtn li a span {
          font-size: 14px;
          padding: 0 8px 8px;
          text-align: left; }
  .wpmemCustomWrap ul:not([class]) > li {
    padding-left: 22px;
    position: relative; }
    .wpmemCustomWrap ul:not([class]) > li:not(:first-of-type) {
      margin-top: 2px; }
    .wpmemCustomWrap ul:not([class]) > li:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 5px;
      top: 11px; }
    .wpmemCustomWrap ul:not([class]) > li > ul {
      margin-top: 10px;
      padding-left: 3px; }
      .wpmemCustomWrap ul:not([class]) > li > ul li {
        padding-left: 13px;
        position: relative;
        color: #828b99; }
        .wpmemCustomWrap ul:not([class]) > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        .wpmemCustomWrap ul:not([class]) > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 11px; }
  ol.hangingListAb li {
    padding-left: 22px; }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #17489c !important; }
  .singleContents .inner > *:first-child {
    margin-top: 0; }
  .singleContents p {
    font-size: 14px; }
  .singleContents p {
    margin-top: 20px;
    margin-bottom: 20px; }
  .singleContents .wp-block-media-text p:first-child {
    margin-top: 20px; }
  .singleContents h2 + p, .singleContents h3 + p, .singleContents h4 + p {
    margin-top: 20px;
    margin-bottom: 20px; }
  .singleContents * + h2 {
    margin-top: 40px; }
  .singleContents * + h3 {
    margin-top: 30px; }
  .singleContents * + h4 {
    margin-top: 30px; }
  .singleContents * + h2,
  .singleContents * + h3,
  .singleContents * + h4 {
    margin-bottom: 20px; }
  .singleContents * + h4 {
    margin-bottom: 15px; }
  .singleContents h2 {
    font-size: 18px;
    padding-left: 24px;
    padding-bottom: 12px; }
    .singleContents h2:after {
      width: 4px;
      height: 4px;
      top: 11px;
      left: 5px; }
  .singleContents h3 {
    font-size: 18px;
    padding-left: 15px; }
  .singleContents h4 {
    font-size: 16px; }
  .singleContents ul > li {
    padding-left: 22px;
    position: relative; }
    .singleContents ul > li:not(:first-of-type) {
      margin-top: 10px; }
    .singleContents ul > li:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 5px;
      top: 11px; }
    .singleContents ul > li > ul {
      margin-top: 10px;
      padding-left: 3px; }
      .singleContents ul > li > ul li {
        padding-left: 13px;
        position: relative;
        color: #828b99; }
        .singleContents ul > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        .singleContents ul > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 11px; }
  .singleContents ol li {
    padding-left: 22px; }
    .singleContents ol li:before {
      position: absolute;
      left: 5px;
      top: 0px;
      font-size: 16px; }
  .singleContents * + ul, .singleContents * + ol {
    margin-top: 20px;
    margin-bottom: 20px; }
  .singleContents * + ol {
    margin-top: 20px;
    margin-bottom: 20px; }
  .singleContents ol.hangingListAb li {
    padding-left: 22px; }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px; }
  .singleContents .wp-block-button {
    margin-bottom: 20px; }
    .singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
      font-size: 14px;
      padding: 15px 30px;
      width: 100%; }
  .singleContents .wp-block-buttons {
    margin-top: 20px;
    margin-bottom: 20px; }
    .singleContents .wp-block-buttons .wp-block-button {
      display: block;
      margin-bottom: 0;
      width: 100%; }
  .singleContents .wp-block-table tr th {
    padding: 10px 20px; }
    .singleContents .wp-block-table tr th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .singleContents .wp-block-table tr td {
    padding: 15px 20px; }
    .singleContents .wp-block-table tr td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }
  .singleContents .wp-block-image {
    margin-bottom: 20px; }
  .singleContents .wp-block-media-text .wp-block-media-text__content {
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0; }
  .singleContents .wp-block-media-text {
    margin-bottom: 20px; }
  .singleContents .wp-block-group {
    margin-bottom: 20px; }
  .singleContents .colorBox {
    padding: 30px; }
    .singleContents .colorBox p.title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
      font-family: 'Teko', sans-serif; }
    .singleContents .colorBox .cont * + p {
      margin: 20px 0 0; }
  * + p {
    margin-top: 20px; }
    * + p.wide {
      margin-top: 40px;
      margin-bottom: 40px; }
  h2, h3, h4 {
    line-height: 1.3; }
  h2 + p, h3 + p {
    margin-top: 20px;
    margin-bottom: 20px; }
  * + h2.title, * + .h2, * + h2.title2 {
    margin-top: 40px; }
  * + h3.title {
    margin-top: 30px; }
  * + h4.title {
    margin-top: 30px; }
  h4 + p {
    margin-top: 15px; }
  h2.title {
    font-size: 18px;
    padding-left: 24px;
    padding-bottom: 12px; }
    h2.title:after {
      width: 4px;
      height: 4px;
      top: 11px;
      left: 5px; }
  h2.title2 {
    font-size: 20px;
    padding-left: 30px; }
    h2.title2:after {
      width: 18px;
      height: 3px;
      top: 13px;
      left: 0; }
  h3.title {
    font-size: 18px;
    padding-left: 15px; }
  h4.title {
    font-size: 16px; }
  ul.commonList > li {
    padding-left: 22px;
    position: relative; }
    ul.commonList > li:not(:first-of-type) {
      margin-top: 10px; }
    ul.commonList > li:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 5px;
      top: 11px; }
    ul.commonList > li > ul {
      margin-top: 10px;
      padding-left: 3px; }
      ul.commonList > li > ul li {
        padding-left: 13px;
        position: relative;
        color: #828b99; }
        ul.commonList > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        ul.commonList > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 11px; }
  ol li {
    padding-left: 22px; }
    ol li:before {
      position: absolute;
      left: 5px;
      top: 0px;
      font-size: 16px; }
  section + section {
    margin-top: 40px; }
  * + .commonList, * + .linkList, * + .colorBox, * + .borderBox, * + .commonTable {
    margin-top: 20px; }
  * + .borderBox {
    margin-top: 40px; }
  * + ol {
    margin-top: 20px; }
  * + .imgLine2, * + .imgLine3, * + .twoCol {
    margin-top: 20px; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: 0px;
      top: .2em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.svg); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_word.svg); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_excel.svg); }
    a.icon.front {
      padding-left: 25px;
      padding-right: 0; }
      a.icon.front:after {
        left: 0px; }
  .commonBtn {
    font-size: 14px;
    padding: 15px 30px;
    width: 100%; }
    .commonBtn .icon {
      width: 20px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle; }
  .imgLine2.spflex {
    display: flex; }
    .imgLine2.spflex li {
      margin: 0;
      width: calc(50% - 8px); }
      .imgLine2.spflex li:nth-of-type(odd) {
        margin-right: 16px; }
  .imgLine2 li {
    text-align: center;
    margin-top: 20px; }
    .imgLine2 li img {
      max-width: 100%; }
  .imgLine2 .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .imgLine3 li {
    text-align: center;
    margin-top: 20px; }
    .imgLine3 li img {
      max-width: 100%; }
  .imgLine3 .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .imgInP .img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px; }
    .imgInP .img .cap {
      display: block;
      text-align: center;
      font-size: 14px;
      margin-top: 10px; }
  .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap; }
    .imgInP.sp_img_btm .img {
      display: block;
      order: 2;
      margin: 20px auto 0;
      padding-bottom: 0; }
    .imgInP.sp_img_btm .text {
      flex-basis: 100%;
      order: 1; }
  .twoCol .txt *:nth-child(1) {
    margin-top: 0; }
  .twoCol .txt + .img {
    margin-top: 20px; }
  .twoCol .img {
    width: 100%;
    text-align: center; }
    .twoCol .img + .txt {
      margin-top: 20px; }
    .twoCol .img img {
      max-width: 100%;
      width: auto;
      height: auto; }
  .twoCol .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .colorBox {
    padding: 30px; }
    .colorBox p.title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
      font-family: 'Teko', sans-serif; }
    .colorBox .cont * + p {
      margin: 20px 0 0; }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 120px; }
  .commonTable th {
    padding: 10px 20px; }
    .commonTable th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .commonTable td {
    padding: 15px 20px; }
    .commonTable td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }
  .commonTable.pdSmall th {
    padding: 10px 10px; }
  .commonTable.pdSmall td {
    padding: 10px 10px; }
  .scrollTable {
    margin-top: 30px; }
    .scrollTable table {
      white-space: nowrap; }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 20px 0 0; }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 20px; }
    .iframeBox iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mB60 {
    margin-bottom: 60px !important; }
  .mB70 {
    margin-bottom: 70px !important; }
  .mB80 {
    margin-bottom: 80px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mT60 {
    margin-top: 60px !important; }
  .mT70 {
    margin-top: 70px !important; }
  .mT80 {
    margin-top: 80px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .size12 {
    font-size: 12px !important; }
  .size14 {
    font-size: 14px !important; }
  .size16 {
    font-size: 16px !important; }
  .size18 {
    font-size: 18px !important; }
  .size20 {
    font-size: 20px !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5_sp {
    margin-bottom: 5px !important; }
  .mB10_sp {
    margin-bottom: 10px !important; }
  .mB20_sp {
    margin-bottom: 20px !important; }
  .mB30_sp {
    margin-bottom: 30px !important; }
  .mB40_sp {
    margin-bottom: 40px !important; }
  .mB50_sp {
    margin-bottom: 50px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5_sp {
    margin-top: 5px !important; }
  .mT10_sp {
    margin-top: 10px !important; }
  .mT20_sp {
    margin-top: 20px !important; }
  .mT30_sp {
    margin-top: 30px !important; }
  .mT40_sp {
    margin-top: 40px !important; }
  .mT50_sp {
    margin-top: 50px !important; }
  .mL0_sp {
    margin-left: 0px !important; }
  .mL5_sp {
    margin-left: 5px !important; }
  .mL10_sp {
    margin-left: 10px !important; }
  .mL20_sp {
    margin-left: 20px !important; }
  .mL30_sp {
    margin-left: 30px !important; }
  .mL40_sp {
    margin-left: 40px !important; }
  .mL50_sp {
    margin-left: 50px !important; }
  .mR0_sp {
    margin-right: 0px !important; }
  .mR5_sp {
    margin-right: 5px !important; }
  .mR10_sp {
    margin-right: 10px !important; }
  .mR20_sp {
    margin-right: 20px !important; }
  .mR30_sp {
    margin-right: 30px !important; }
  .mR40_sp {
    margin-right: 40px !important; }
  .mR50_sp {
    margin-right: 50px !important; }
  .size12_sp {
    font-size: 12px !important; }
  .size14_sp {
    font-size: 14px !important; }
  .size16_sp {
    font-size: 16px !important; }
  .size18_sp {
    font-size: 18px !important; }
  .size20_sp {
    font-size: 20px !important; }
  .w50p_sp {
    width: 50% !important; }
  .red {
    color: #de0000 !important; }
  .blue {
    color: #8b99ff !important; }
  .gray {
    color: #666 !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .green {
    color: #5cc49c !important; }
  .pink {
    color: #ff9191 !important; }
  .bdb {
    border-bottom: 2px solid #004aa4; }
  .fontL {
    font-size: 1.2em; }
  .fontS {
    font-size: .8em; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17489c;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }

@media print, screen and (min-width: 1001px) {
  main {
    background: url(../images/second/second_cont_bg.png) no-repeat top center;
    background-size: 100% auto; }
  .breadcrumb {
    padding: 20px 0;
    margin-top: 120px; }
    .breadcrumb .commonInner > span {
      font-size: 13px; }
      .breadcrumb .commonInner > span:not(:last-of-type) {
        margin-right: 50px; }
        .breadcrumb .commonInner > span:not(:last-of-type):after {
          content: "";
          display: inline-block;
          width: 12px;
          height: 1px;
          background-color: #6f99d9;
          position: absolute;
          top: 11px;
          right: -32px; }
    .breadcrumb .commonInner a:hover {
      text-decoration: underline; }
  .pageTitleWrap {
    height: 250px;
    display: flex;
    align-items: center; }
    .pageTitleWrap .pageTitleInner {
      width: 90%;
      max-width: 1280px;
      margin: 0 auto; }
    .pageTitleWrap .pageTitleEn {
      font-size: 72px; }
    .pageTitleWrap .pageTitle {
      font-size: 21px; }
  h1.title + * {
    margin-top: 40px; }
  h1.title {
    font-size: 34px;
    padding-bottom: 26px; }
  h1.title2 {
    font-size: 30px;
    padding-left: 38px; }
    h1.title2:after {
      width: 20px;
      height: 3px;
      top: 21px;
      left: 0; }
  .productsList {
    margin-top: 80px; }
    .productsList .productsItem {
      display: flex;
      flex-direction: row-reverse; }
      .productsList .productsItem + .productsItem {
        margin-top: 20px; }
      .productsList .productsItem figure {
        width: 50%; }
        .productsList .productsItem figure img {
          object-fit: cover;
          width: 100%;
          height: 100%; }
      .productsList .productsItem .productsItemText {
        width: 50%;
        padding: 130px 50px 80px 80px; }
        .productsList .productsItem .productsItemText .num {
          position: absolute;
          font-size: 52px;
          top: 50px;
          left: 50px; }
        .productsList .productsItem .productsItemText .title {
          font-size: 30px; }
        .productsList .productsItem .productsItemText .text {
          margin-top: 40px; }
        .productsList .productsItem .productsItemText .img {
          margin-top: 40px; }
        .productsList .productsItem .productsItemText .viewmore {
          text-align: right;
          padding-right: 40px;
          position: absolute;
          bottom: 40px;
          right: 0px; }
          .productsList .productsItem .productsItemText .viewmore a {
            font-size: 18px;
            padding-bottom: 15px;
            padding-right: 45px; }
            .productsList .productsItem .productsItemText .viewmore a:before {
              content: "";
              display: inline-block;
              width: 100%;
              height: 4px;
              background-color: #17489c;
              position: absolute;
              bottom: 0;
              left: 0; }
  @keyframes lineAnime {
    0% {
      width: 0; }
    100% {
      width: 100%; } }
            .productsList .productsItem .productsItemText .viewmore a:hover:before {
              animation: lineAnime 0.8s; }
  .contWhiteBox {
    padding: 70px 70px 0; }
  * + .contWhiteBox {
    margin-top: 50px; }
  .commonDL {
    margin-top: 20px; }
    .commonDL dl {
      display: flex;
      padding: 25px 0; }
      .commonDL dl dt {
        padding-left: 15px;
        width: 20%; }
      .commonDL dl dd {
        width: 80%;
        padding-left: 50px; }
  .historyWrap {
    margin-top: 50px;
    padding: 50px 60px; }
    .historyWrap dl {
      display: flex;
      padding: 20px 0; }
      .historyWrap dl dt {
        width: 20%;
        padding-left: 24px; }
        .historyWrap dl dt:before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 2px;
          background-color: #17489c;
          position: absolute;
          left: 0;
          top: 13px; }
      .historyWrap dl dd {
        width: 80%; }
  .gMap {
    margin-top: 50px;
    padding: 0 70px; }
    .gMap iframe {
      height: 350px; }
  .productList {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap; }
    .productList .productListItem {
      width: calc(50% - 40px); }
      .productList .productListItem:nth-of-type(even) {
        margin-left: 80px; }
      .productList .productListItem:nth-of-type(n + 3) {
        margin-top: 80px; }
      .productList .productListItem a:hover .title:after {
        right: 0; }
      .productList .productListItem .textWrap {
        margin-top: 30px;
        padding-left: 30px; }
        .productList .productListItem .textWrap .title {
          font-size: 22px;
          padding-right: 70px; }
          .productList .productListItem .textWrap .title:after {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_arrow.svg) no-repeat;
            width: 22px;
            height: 22px;
            position: absolute;
            right: 30px;
            bottom: 5px;
            transition: .3s; }
  .productDetailMain {
    display: flex; }
    .productDetailMain .productDetailMainText {
      width: 47%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 30px; }
      .productDetailMain .productDetailMainText .tag {
        position: absolute;
        top: 0;
        left: 0; }
        .productDetailMain .productDetailMainText .tag span {
          font-size: 14px;
          padding: 3px 40px; }
          .productDetailMain .productDetailMainText .tag span:before {
            content: "";
            display: inline-block;
            background-color: #17489c;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            top: 4px;
            left: 4px; }
      .productDetailMain .productDetailMainText .mainTitle {
        font-size: 48px; }
        .productDetailMain .productDetailMainText .mainTitle + p {
          font-size: 18px;
          color: #9ea4ad;
          margin-top: 30px; }
    .productDetailMain figure {
      width: 53%; }
  .anchorList {
    padding: 15px 5%;
    margin-top: 80px;
    justify-content: space-between; }
    .anchorList a {
      font-size: 14px;
      padding-right: 20px; }
      .anchorList a:hover:after {
        top: calc(50%); }
      .anchorList a + a {
        margin-left: 5%; }
      .anchorList a:after {
        content: '';
        width: 6px;
        height: 6px;
        border: 0px;
        border-bottom: solid 2px #17489c;
        border-right: solid 2px #17489c;
        transform: rotate(45deg);
        position: absolute;
        top: calc(50% - 5px);
        right: 0px;
        transition: .3s; }
  .meritBox {
    padding: 50px 50px 50px; }
    .meritBox .meritTitle {
      font-size: 26px;
      padding-left: 30px;
      top: -0.5em;
      left: 25px; }
      .meritBox .meritTitle span {
        font-family: 'Teko', sans-serif;
        font-size: 22px;
        letter-spacing: normal; }
      .meritBox .meritTitle:before {
        content: "";
        display: inline-block;
        background-color: #17489c;
        width: 20px;
        height: 2px;
        position: absolute;
        top: 18px;
        left: 0; }
    .meritBox .meritBoxList {
      display: flex;
      flex-wrap: wrap; }
      .meritBox .meritBoxList .meritBoxItem {
        width: calc(50% - 25px); }
        .meritBox .meritBoxList .meritBoxItem:nth-of-type(even) {
          margin-left: 50px; }
        .meritBox .meritBoxList .meritBoxItem:nth-of-type(n + 3) {
          margin-top: 40px;
          padding-top: 40px;
          border-top: 2px solid #e6ecf5; }
        .meritBox .meritBoxList .meritBoxItem h4 {
          font-size: 18px; }
        .meritBox .meritBoxList .meritBoxItem .textWrap {
          margin-top: 15px;
          display: flex; }
          .meritBox .meritBoxList .meritBoxItem .textWrap p {
            width: 64%; }
          .meritBox .meritBoxList .meritBoxItem .textWrap figure {
            width: 36%;
            padding-left: 30px; }
  .surunaraArrow {
    font-size: 26px;
    margin-top: 50px;
    padding-bottom: 40px; }
  .numTitle {
    margin-top: 50px; }
    .numTitle + p {
      margin-top: 30px; }
    .numTitle span {
      font-size: 20px;
      padding-right: 15px; }
    .numTitle h3 {
      font-size: 22px;
      padding-left: 15px; }
  .viewmore {
    text-align: right;
    margin-top: 10px; }
    .viewmore a {
      font-size: 16px;
      padding-bottom: 10px;
      padding-right: 40px; }
      .viewmore a:before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: #17489c;
        position: absolute;
        bottom: 0;
        left: 0; }
  @keyframes lineAnime {
    0% {
      width: 0; }
    100% {
      width: 100%; } }
      .viewmore a:hover:before {
        animation: lineAnime 0.8s; }
  .shadowBox {
    margin-top: 50px; }
    .shadowBox .cont {
      padding: 30px 50px; }
  .btnTwoCol {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; }
    .btnTwoCol > div {
      width: calc(50% - 20px); }
      .btnTwoCol > div .commonBtn {
        width: 100%; }
  .qaBox {
    margin-top: 40px; }
    .qaBox dl + dl {
      margin-top: 40px; }
    .qaBox dl dt {
      font-size: 18px; }
    .qaBox dl dd {
      margin-top: 20px; }
  .wp-pagenavi {
    margin-top: 70px; }
    .wp-pagenavi .page, .wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .extend, .wp-pagenavi .last, .wp-pagenavi .first {
      font-size: 20px;
      min-width: 38px;
      min-height: 38px;
      margin: 0 5px; }
      .wp-pagenavi .page:hover, .wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover, .wp-pagenavi .extend:hover, .wp-pagenavi .last:hover, .wp-pagenavi .first:hover {
        background-color: #17489c;
        color: #fff; }
    .wp-pagenavi .extend:hover {
      background: none !important;
      color: #17489c !important; }
    .wp-pagenavi span.current {
      font-size: 20px;
      min-width: 38px;
      min-height: 38px;
      margin: 0 5px; }
    .wp-pagenavi .previouspostslink:before {
      top: calc(50% - 2px);
      left: 50%; }
    .wp-pagenavi .nextpostslink:before {
      top: calc(50% - 2px);
      right: 50%; }
  .postYmd {
    margin-top: 20px;
    padding-left: 38px; }
    .postYmd .ymd .year {
      font-size: 18px; }
    .postYmd .ymd .monthday {
      font-size: 28px; }
    .postYmd .tag {
      min-width: 120px;
      flex: none;
      font-size: 13px;
      padding: 10px 10px; }
  .contact_wrap input {
    padding: 15px 20px; }
    .contact_wrap input.sizeS {
      width: 20%; }
    .contact_wrap input.sizeM {
      width: 40%; }
    .contact_wrap input::placeholder {
      color: #D1D1D1; }
  .contact_wrap select.sizeS {
    width: 20%; }
  .contact_wrap select.sizeM {
    width: 40%; }
  .contact_wrap select::placeholder {
    color: #D1D1D1; }
  .contact_wrap textarea {
    padding: 15px 20px; }
  .contact_wrap > dl {
    display: flex; }
    .contact_wrap > dl:not(:first-of-type) {
      margin-top: 40px; }
    .contact_wrap > dl > dt {
      width: 270px;
      padding-right: 50px; }
      .contact_wrap > dl > dt .hissu {
        background-color: red;
        color: #fff;
        font-size: 12px;
        padding: 5px 5px;
        line-height: 1;
        display: inline-block;
        margin-left: 10px; }
    .contact_wrap > dl > dd {
      width: calc(100% - 270px); }
      .contact_wrap > dl > dd .attention {
        display: block;
        font-size: 12px;
        margin-top: 5px; }
      .contact_wrap > dl > dd > dl:not(:first-of-type) {
        margin-top: 20px; }
      .contact_wrap > dl > dd > dl > dt {
        font-size: 12px;
        margin-bottom: 5px; }
    .contact_wrap > dl.uploadItem > dd {
      position: relative; }
      .contact_wrap > dl.uploadItem > dd .mwform-file-delete {
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 12px);
        right: 20px; }
  .contact_wrap .btn_wrap {
    margin-top: 40px; }
    .contact_wrap .btn_wrap .commonBtn:hover {
      background-color: #17489c;
      color: #fff; }
    .contact_wrap .btn_wrap .commonBtn + .commonBtn {
      margin-left: 20px; }
    .contact_wrap .btn_wrap .commonBtn.back {
      min-width: 200px; }
  .contact_wrap .agreeBox {
    margin-top: 40px; }
    .contact_wrap .agreeBox .agreeBoxNotes {
      text-align: center; }
      .contact_wrap .agreeBox .agreeBoxNotes a {
        color: #000000;
        text-decoration: underline; }
        .contact_wrap .agreeBox .agreeBoxNotes a:hover {
          text-decoration: none; }
  * + .tabLinkBtn {
    margin-top: 40px; }
  .tabLinkBtn {
    margin-bottom: 40px; }
    .tabLinkBtn li:not(:last-child) {
      margin-right: 64px; }
    .tabLinkBtn li a span {
      font-size: 18px;
      padding: 10px 34px; }
      .tabLinkBtn li a span:hover {
        border-bottom-color: #17489c;
        color: #17489c;
        font-weight: bold; }
  .wpmemCustomWrap ul:not([class]) > li {
    padding-left: 40px;
    position: relative; }
    .wpmemCustomWrap ul:not([class]) > li:not(:first-of-type) {
      margin-top: 2px; }
    .wpmemCustomWrap ul:not([class]) > li:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 15px;
      top: 14px; }
    .wpmemCustomWrap ul:not([class]) > li > ul {
      margin-top: 15px;
      padding-left: 10px; }
      .wpmemCustomWrap ul:not([class]) > li > ul li {
        padding-left: 15px;
        position: relative;
        color: #828b99; }
        .wpmemCustomWrap ul:not([class]) > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        .wpmemCustomWrap ul:not([class]) > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 13px; }
  ol.hangingListAb li {
    padding-left: 40px; }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #17489c !important; }
  .singleContents .inner > *:first-child {
    margin-top: 0; }
  .singleContents p {
    font-size: 16px; }
  .singleContents p {
    margin-top: 40px;
    margin-bottom: 40px; }
  .singleContents .wp-block-media-text p:first-child {
    margin-top: 40px; }
  .singleContents h2 + p, .singleContents h3 + p, .singleContents h4 + p {
    margin-top: 30px;
    margin-bottom: 30px; }
  .singleContents * + h2 {
    margin-top: 80px; }
  .singleContents * + h3 {
    margin-top: 40px; }
  .singleContents * + h4 {
    margin-top: 60px; }
  .singleContents * + h2,
  .singleContents * + h3,
  .singleContents * + h4 {
    margin-bottom: 30px; }
  .singleContents * + h4 {
    margin-bottom: 15px; }
  .singleContents h2 {
    font-size: 26px;
    padding-left: 36px;
    padding-bottom: 15px; }
    .singleContents h2:after {
      width: 6px;
      height: 6px;
      top: 15px;
      left: 9px; }
  .singleContents h3 {
    font-size: 22px;
    padding-left: 22px; }
  .singleContents h4 {
    font-size: 18px; }
  .singleContents ul > li {
    padding-left: 40px;
    position: relative; }
    .singleContents ul > li:not(:first-of-type) {
      margin-top: 10px; }
    .singleContents ul > li:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 15px;
      top: 14px; }
    .singleContents ul > li > ul {
      margin-top: 15px;
      padding-left: 10px; }
      .singleContents ul > li > ul li {
        padding-left: 15px;
        position: relative;
        color: #828b99; }
        .singleContents ul > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        .singleContents ul > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 13px; }
  .singleContents ol li {
    padding-left: 40px; }
    .singleContents ol li:before {
      position: absolute;
      left: 18px;
      top: 0;
      font-size: 19px; }
  .singleContents * + ul, .singleContents * + ol {
    margin-top: 30px;
    margin-bottom: 30px; }
  .singleContents * + ol {
    margin-top: 30px;
    margin-bottom: 30px; }
  .singleContents ol.hangingListAb li {
    padding-left: 40px; }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px; }
  .singleContents .wp-block-button {
    margin-bottom: 40px; }
    .singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
      font-size: 17px;
      padding: 15px 30px;
      min-width: 320px;
      transition: .3s; }
      .singleContents .wp-block-button a:hover, .singleContents .wp-block-button .wp-block-button__link:hover {
        background-color: #17489c;
        color: #fff; }
    .singleContents .wp-block-button .wp-block-button__link:hover {
      background-color: #17489c;
      color: #fff; }
    .singleContents .wp-block-button.is-style-outline .wp-block-button__link:hover {
      background-color: #17489c;
      color: #fff; }
  .singleContents .wp-block-buttons {
    margin-top: 40px;
    margin-bottom: 40px; }
    .singleContents .wp-block-buttons .wp-block-button {
      margin-bottom: 0; }
  .singleContents .wp-block-table tr th {
    padding: 10px 30px;
    font-size: 14px; }
    .singleContents .wp-block-table tr th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .singleContents .wp-block-table tr td {
    padding: 15px 30px;
    font-size: 14px; }
    .singleContents .wp-block-table tr td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }
  .singleContents .wp-block-table.customTable th {
    width: 250px; }
  .singleContents .wp-block-table.customTable td {
    width: calc(100% - 250px); }
  .singleContents .wp-block-image {
    margin-bottom: 40px; }
  .singleContents .wp-block-media-text {
    margin-bottom: 30px; }
  .singleContents .wp-block-group {
    margin-bottom: 30px; }
  .singleContents .colorBox {
    padding: 50px 50px; }
    .singleContents .colorBox p.title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
      font-family: 'Teko', sans-serif; }
    .singleContents .colorBox .cont * + p {
      margin: 30px 0 0; }
  * + p {
    margin-top: 30px; }
    * + p.wide {
      margin-top: 60px;
      margin-bottom: 60px; }
  h2, h3, h4 {
    line-height: 1.3; }
  h2 + p, h3 + p {
    margin-top: 30px;
    margin-bottom: 30px; }
  * + h2.title, * + .h2, * + h2.title2 {
    margin-top: 80px; }
  * + h3.title {
    margin-top: 40px; }
  * + h4.title {
    margin-top: 60px; }
  h4 + p {
    margin-top: 15px; }
  h2.title {
    font-size: 26px;
    padding-left: 36px;
    padding-bottom: 15px; }
    h2.title:after {
      width: 6px;
      height: 6px;
      top: 15px;
      left: 9px; }
  h2.title2 {
    font-size: 30px;
    padding-left: 38px; }
    h2.title2:after {
      width: 20px;
      height: 3px;
      top: 21px;
      left: 0; }
  h3.title {
    font-size: 22px;
    padding-left: 22px; }
  h4.title {
    font-size: 18px; }
  ul.commonList > li {
    padding-left: 40px;
    position: relative; }
    ul.commonList > li:not(:first-of-type) {
      margin-top: 10px; }
    ul.commonList > li:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 2px;
      background-color: #17489c;
      position: absolute;
      left: 15px;
      top: 14px; }
    ul.commonList > li > ul {
      margin-top: 15px;
      padding-left: 10px; }
      ul.commonList > li > ul li {
        padding-left: 15px;
        position: relative;
        color: #828b99; }
        ul.commonList > li > ul li:not(:first-of-type) {
          margin-top: 10px; }
        ul.commonList > li > ul li:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #828b99;
          position: absolute;
          left: 0px;
          top: 13px; }
  ol li {
    padding-left: 40px; }
    ol li:before {
      position: absolute;
      left: 18px;
      top: 0;
      font-size: 19px; }
  section + section {
    margin-top: 80px; }
  * + .commonList, * + .linkList, * + .colorBox, * + .borderBox, * + .commonTable {
    margin-top: 40px; }
  * + .borderBox {
    margin-top: 60px; }
  * + ol {
    margin-top: 30px; }
  * + .imgLine2, * + .imgLine3, * + .twoCol {
    margin-top: 30px; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: 0px;
      top: .2em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.svg); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_word.svg); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_excel.svg); }
    a.icon.front {
      padding-left: 25px;
      padding-right: 0; }
      a.icon.front:after {
        left: 0px; }
  .commonBtn {
    font-size: 17px;
    padding: 15px 30px;
    min-width: 320px;
    transition: .3s; }
    .commonBtn:hover {
      background-color: #17489c;
      color: #fff; }
    .commonBtn.sizeL {
      width: 60%; }
    .commonBtn .icon {
      width: 24px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle; }
    .commonBtn.gradeBtn:hover {
      background-position: 100% 0; }
    .commonBtn.taikenBtn:hover {
      opacity: .7; }
  .imgLine2 {
    display: flex;
    flex-wrap: wrap; }
    .imgLine2 li {
      text-align: center;
      width: calc(50% - 20px); }
      .imgLine2 li:nth-of-type(odd) {
        margin-right: 40px; }
      .imgLine2 li:nth-of-type(n + 3) {
        margin-top: 40px; }
      .imgLine2 li img {
        max-width: 100%; }
    .imgLine2.small {
      width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .imgLine2 .cap {
      display: block;
      text-align: center;
      font-size: 14px;
      margin-top: 10px; }
  .imgLine3 {
    display: flex;
    flex-wrap: wrap; }
    .imgLine3 li {
      text-align: center;
      width: calc((100% / 3 ) - 17px);
      margin-right: 25px; }
      .imgLine3 li:nth-of-type(3n) {
        margin-right: 0; }
      .imgLine3 li:nth-of-type(n + 4) {
        margin-top: 25px; }
      .imgLine3 li img {
        max-width: 100%; }
    .imgLine3 .cap {
      display: block;
      text-align: center;
      font-size: 14px;
      margin-top: 10px; }
  .imgInP {
    overflow: hidden; }
    .imgInP .img {
      max-width: 300px; }
      .imgInP .img img {
        width: 100%;
        height: auto; }
      .imgInP .img .cap {
        display: block;
        text-align: center;
        font-size: 14px;
        margin-top: 10px; }
    .imgInP .img.left {
      float: left;
      margin-right: 40px; }
    .imgInP .img.right {
      float: right;
      margin-left: 40px; }
  .twoCol {
    display: flex; }
    .twoCol .txt {
      flex: 1; }
      .twoCol .txt *:nth-child(1) {
        margin-top: 0; }
      .twoCol .txt + .img {
        margin-left: 40px; }
    .twoCol .img {
      max-width: 50%; }
      .twoCol .img + .txt {
        margin-left: 40px; }
      .twoCol .img img {
        max-width: 100%;
        width: auto;
        height: auto; }
    .twoCol .cap {
      display: block;
      text-align: center;
      font-size: 14px;
      margin-top: 10px; }
  .colorBox {
    padding: 50px 50px; }
    .colorBox p.title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
      font-family: 'Teko', sans-serif; }
    .colorBox .cont * + p {
      margin: 30px 0 0; }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 150px; }
  .commonTable th {
    padding: 10px 30px;
    font-size: 14px; }
    .commonTable th.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c;
      background-color: #fff; }
  .commonTable td {
    padding: 15px 30px;
    font-size: 14px; }
    .commonTable td.null {
      border-top: 1px solid #17489c;
      border-left: 1px solid #17489c; }
  .commonTable.pdSmall th {
    padding: 10px 10px; }
  .commonTable.pdSmall td {
    padding: 10px 10px; }
  .scrollTable {
    margin-top: 40px; }
  .scrollText {
    display: none; }
  .iframeWrap {
    max-width: 720px;
    margin: 0 auto; }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 40px; }
    .iframeBox iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mB60 {
    margin-bottom: 60px !important; }
  .mB70 {
    margin-bottom: 70px !important; }
  .mB80 {
    margin-bottom: 80px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mT60 {
    margin-top: 60px !important; }
  .mT70 {
    margin-top: 70px !important; }
  .mT80 {
    margin-top: 80px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .size12 {
    font-size: 12px !important; }
  .size14 {
    font-size: 14px !important; }
  .size16 {
    font-size: 16px !important; }
  .size18 {
    font-size: 18px !important; }
  .size20 {
    font-size: 20px !important; }
  .mB0_pc {
    margin-bottom: 0 !important; }
  .mB5_pc {
    margin-bottom: 5px !important; }
  .mB10_pc {
    margin-bottom: 10px !important; }
  .mB20_pc {
    margin-bottom: 20px !important; }
  .mB30_pc {
    margin-bottom: 30px !important; }
  .mB40_pc {
    margin-bottom: 40px !important; }
  .mB50_pc {
    margin-bottom: 50px !important; }
  .mB60_pc {
    margin-bottom: 60px !important; }
  .mB70_pc {
    margin-bottom: 70px !important; }
  .mB80_pc {
    margin-bottom: 80px !important; }
  .mT0_pc {
    margin-top: 0 !important; }
  .mT5_pc {
    margin-top: 5px !important; }
  .mT10_pc {
    margin-top: 10px !important; }
  .mT20_pc {
    margin-top: 20px !important; }
  .mT30_pc {
    margin-top: 30px !important; }
  .mT40_pc {
    margin-top: 40px !important; }
  .mT50_pc {
    margin-top: 50px !important; }
  .mT60_pc {
    margin-top: 60px !important; }
  .mT70_pc {
    margin-top: 70px !important; }
  .mT80_pc {
    margin-top: 80px !important; }
  .mL0_pc {
    margin-left: 0px !important; }
  .mL5_pc {
    margin-left: 5px !important; }
  .mL10_pc {
    margin-left: 10px !important; }
  .mL20_pc {
    margin-left: 20px !important; }
  .mL30_pc {
    margin-left: 30px !important; }
  .mL40_pc {
    margin-left: 40px !important; }
  .mL50_pc {
    margin-left: 50px !important; }
  .mR0_pc {
    margin-right: 0px !important; }
  .mR5_pc {
    margin-right: 5px !important; }
  .mR10_pc {
    margin-right: 10px !important; }
  .mR20_pc {
    margin-right: 20px !important; }
  .mR30_pc {
    margin-right: 30px !important; }
  .mR40_pc {
    margin-right: 40px !important; }
  .mR50_pc {
    margin-right: 50px !important; }
  .size12_pc {
    font-size: 12px !important; }
  .size14_pc {
    font-size: 14px !important; }
  .size16_pc {
    font-size: 16px !important; }
  .size18_pc {
    font-size: 18px !important; }
  .size20_pc {
    font-size: 20px !important; }
  .red {
    color: #de0000 !important; }
  .blue {
    color: #8b99ff !important; }
  .gray {
    color: #666 !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .green {
    color: #5cc49c !important; }
  .pink {
    color: #ff9191 !important; }
  .bdb {
    border-bottom: 2px solid #004aa4; }
  .fontL {
    font-size: 1.2em; }
  .fontS {
    font-size: .8em; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17489c;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }

@media screen and (min-width: 1001px) and (max-width: 1140px) {
  .wp-pagenavi .extend:hover {
    background: none !important;
    color: #17489c !important; }
  .contact_wrap input::placeholder {
    color: #D1D1D1; }
  .contact_wrap select::placeholder {
    color: #D1D1D1; }
  .contact_wrap > dl > dt .hissu {
    background-color: red;
    color: #fff;
    font-size: 12px;
    padding: 5px 5px;
    line-height: 1;
    display: inline-block;
    margin-left: 10px; }
  .contact_wrap > dl > dd .attention {
    display: block;
    font-size: 12px;
    margin-top: 5px; }
  .contact_wrap > dl.uploadItem > dd {
    position: relative; }
    .contact_wrap > dl.uploadItem > dd .mwform-file-delete {
      position: absolute;
      transform: translateY(-50%); }
  .contact_wrap .agreeBox .agreeBoxNotes a {
    color: #000000;
    text-decoration: underline; }
    .contact_wrap .agreeBox .agreeBoxNotes a:hover {
      text-decoration: none; }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #17489c !important; }
  .singleContents .inner > *:first-child {
    margin-top: 0; }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px; }
  .singleContents .wp-block-table tr th.null {
    border-top: 1px solid #17489c;
    border-left: 1px solid #17489c;
    background-color: #fff; }
  .singleContents .wp-block-table tr td.null {
    border-top: 1px solid #17489c;
    border-left: 1px solid #17489c; }
  h2, h3, h4 {
    line-height: 1.3; }
  h4 + p {
    margin-top: 15px; }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px; }
    a.icon:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      position: absolute;
      right: 0px;
      top: .2em; }
    a.icon[href$=pdf]:after {
      background-image: url(../images/common/icon_pdf.svg); }
    a.icon[href$=doc]:after, a.icon[href$=docx]:after {
      background-image: url(../images/common/icon_word.svg); }
    a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
      background-image: url(../images/common/icon_excel.svg); }
    a.icon.front {
      padding-left: 25px;
      padding-right: 0; }
      a.icon.front:after {
        left: 0px; }
  .imgLine2 li {
    text-align: center; }
    .imgLine2 li img {
      max-width: 100%; }
  .imgLine2 .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .imgLine3 li {
    text-align: center; }
    .imgLine3 li img {
      max-width: 100%; }
  .imgLine3 .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .imgInP .img .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .twoCol .cap {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-top: 10px; }
  .commonTable th.null {
    border-top: 1px solid #17489c;
    border-left: 1px solid #17489c;
    background-color: #fff; }
  .commonTable td.null {
    border-top: 1px solid #17489c;
    border-left: 1px solid #17489c; }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%; }
    .iframeBox iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none; }
  .taC {
    text-align: center !important; }
  .taL {
    text-align: left !important; }
  .taR {
    text-align: right !important; }
  .Center {
    margin: 0 auto !important; }
  .ovh {
    overflow: hidden !important; }
  .mw100 {
    max-width: 100% !important;
    width: auto !important; }
  .w100p {
    width: 100% !important; }
  .mB0 {
    margin-bottom: 0 !important; }
  .mB5 {
    margin-bottom: 5px !important; }
  .mB10 {
    margin-bottom: 10px !important; }
  .mB20 {
    margin-bottom: 20px !important; }
  .mB30 {
    margin-bottom: 30px !important; }
  .mB40 {
    margin-bottom: 40px !important; }
  .mB50 {
    margin-bottom: 50px !important; }
  .mB60 {
    margin-bottom: 60px !important; }
  .mB70 {
    margin-bottom: 70px !important; }
  .mB80 {
    margin-bottom: 80px !important; }
  .mT0 {
    margin-top: 0 !important; }
  .mT5 {
    margin-top: 5px !important; }
  .mT10 {
    margin-top: 10px !important; }
  .mT20 {
    margin-top: 20px !important; }
  .mT30 {
    margin-top: 30px !important; }
  .mT40 {
    margin-top: 40px !important; }
  .mT50 {
    margin-top: 50px !important; }
  .mT60 {
    margin-top: 60px !important; }
  .mT70 {
    margin-top: 70px !important; }
  .mT80 {
    margin-top: 80px !important; }
  .mL0 {
    margin-left: 0px !important; }
  .mL5 {
    margin-left: 5px !important; }
  .mL10 {
    margin-left: 10px !important; }
  .mL20 {
    margin-left: 20px !important; }
  .mL30 {
    margin-left: 30px !important; }
  .mL40 {
    margin-left: 40px !important; }
  .mL50 {
    margin-left: 50px !important; }
  .mR0 {
    margin-right: 0px !important; }
  .mR5 {
    margin-right: 5px !important; }
  .mR10 {
    margin-right: 10px !important; }
  .mR20 {
    margin-right: 20px !important; }
  .mR30 {
    margin-right: 30px !important; }
  .mR40 {
    margin-right: 40px !important; }
  .mR50 {
    margin-right: 50px !important; }
  .size12 {
    font-size: 12px !important; }
  .size14 {
    font-size: 14px !important; }
  .size16 {
    font-size: 16px !important; }
  .size18 {
    font-size: 18px !important; }
  .size20 {
    font-size: 20px !important; }
  .red {
    color: #de0000 !important; }
  .blue {
    color: #8b99ff !important; }
  .gray {
    color: #666 !important; }
  .bold {
    font-weight: bold !important; }
  .underline {
    text-decoration: underline !important; }
  .green {
    color: #5cc49c !important; }
  .pink {
    color: #ff9191 !important; }
  .bdb {
    border-bottom: 2px solid #004aa4; }
  .fontL {
    font-size: 1.2em; }
  .fontS {
    font-size: .8em; }
  .floatL {
    float: left; }
  .floatR {
    float: right; }
  .preBox {
    background: #eff0f4;
    border: 1px solid #17489c;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8; }
    .preBox pre {
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      text-align-last: auto; } }

/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:1000px;
$breakpoint_tb		:1140px;
$breakpoint_pc		:$breakpoint_sp + 1;


/* Color */
$c_base : #17489c; /*サイトカラー*/
$c_text : #000000; /*テキストに使うフォントカラー*/
$c_href : #000000; /*テキスト内で使うリンクカラー*/
$c_border : #ccc; /*テキスト内で使うリンクカラー*/
$c_blue : #538ef4;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Teko:wght@300;400;500;600&display=swap');

@mixin maxPC {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
}

@mixin fontUsu {
    font-family: 'Noto Sans JP', sans-serif;
}

@mixin fontEn{
    font-family: 'Teko', sans-serif;
}
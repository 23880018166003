main{
    @if $type == ""{
    }
    @if $type == sp{
        background: url(../images/second/second_cont_bg_sp.png) no-repeat top center;
        background-size: 100% auto;
    }
    @if $type == pc{
        background: url(../images/second/second_cont_bg.png) no-repeat top center;
        background-size: 100% auto;
    }
}
.breadcrumb{
    @if $type == ""{
        display: flex;
        flex-wrap: wrap;
        background-color: #e6ecf5;
    }
    @if $type == sp{
        padding: 10px 0px;
        margin-top: 60px;
    }
    @if $type == pc{
        padding: 20px 0;
        margin-top: 120px;
    }
    .commonInner{
        > span{
            @if $type == ""{
                display: inline-block;
                position: relative;
                color: #6f99d9;
            }
            @if $type==sp {
                font-size: 11px;
                &:not(:last-of-type){
                    margin-right: 30px;
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 10px;
                        height: 1px;
                        background-color: #6f99d9;
                        position: absolute;
                        top: 10px;
                        right: -21px;
                    }
                }
            }
            @if $type==pc {
                font-size: 13px;
                &:not(:last-of-type){
                    margin-right: 50px;
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 1px;
                        background-color: #6f99d9;
                        position: absolute;
                        top: 11px;
                        right: -32px;
                    }
                }
            }
        }
        a{
            @if $type == ""{
                color: #6f99d9;
            }
            @if $type==pc {
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    
}
.pageTitleWrap{
    @if $type == ""{
    }
    @if $type == sp{
        height: 165px;
        display: flex;
        align-items: center;
    }
    @if $type == pc{
        height: 250px;
        display: flex;
        align-items: center;
    }
    .pageTitleInner{
        @if $type == sp{
            padding: 0 20px;
        }
        @if $type == pc{
            @include maxPC;
        }
    }
    .pageTitleEn{
        @if $type == ""{
            color: $c_base;
            @include fontEn;
            line-height: 1;
            font-weight: 600;
            text-transform: uppercase;
        }
        @if $type == sp{
            font-size: 36px;
        }
        @if $type == pc{
            font-size: 72px;
        }
    }
    .pageTitle{
        @if $type == ""{
            color: $c_base;
            font-weight: 400;
        }
        @if $type == sp{
            font-size: 14px;
        }
        @if $type == pc{
            font-size: 21px;
        }
    }
}
h1.title + * {
    @if $type == ""{
        
    }

    @if $type == sp{
        margin-top: 26px;
    }

    @if $type == pc{
        margin-top: 40px;
    }
}

h1.title{
    @if $type == ""{
        font-weight: bold;
        color: $c_base;
        position: relative;
        line-height: 1.3;
    }
    @if $type == sp{
        font-size: 20px;
        padding-bottom: 17px;
    }
    @if $type == pc{
        font-size: 34px;
        padding-bottom: 26px;
    }

    &:before{
        @if $type == ""{
            content: "";
            background: #e6ecf5;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            height: 2px;
        }

        @if $type == sp{}

        @if $type == pc{}
    }

    &:after{
        @if $type == ""{
            content: "";
            background: $c_base;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            height: 2px;
        }
        @if $type == sp{
            
        }
        @if $type == pc{
            
        }
    }
}

h1.title2{
    @if $type == ""{
        font-weight: bold;
        color: $c_base;
        position: relative;
        line-height: 1.3;
    }
    @if $type == sp{
        font-size: 20px;
        padding-left: 30px;
    }
    @if $type == pc{
        font-size: 30px;
        padding-left: 38px;
    }
    &:after{
        @if $type == ""{
            content: "";
            display: block;
            background-color: $c_base;
            position: absolute;
        }
        @if $type == sp{
            width: 18px;
            height: 3px;
            top: 13px;
            left: 0;
        }
        @if $type == pc{
            width: 20px;
            height: 3px;
            top: 21px;
            left: 0;
        }
    }
}

.productsList{
    @if $type == ""{
    }
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 80px;
    }
    .productsItem{
        @if $type == ""{
            box-shadow: 0px 6px 6px 4px rgba(23,72,156,0.1);
        }
        @if $type == sp{
            + .productsItem{
                margin-top: 20px;
            }
        }
        @if $type == pc{
            display: flex;
            flex-direction: row-reverse;
            + .productsItem{
                margin-top: 20px;
            }
        }
        figure{
            @if $type == ""{
                margin: 0;
            }
            @if $type == sp{
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 200px;
                }
            }
            @if $type == pc{
                width: 50%;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .productsItemText{
            @if $type == ""{
                position: relative;
                color: $c_base;
                background-color: #fff;
            }
            @if $type == sp{
                padding: 20px 20px 30px 20px;
            }
            @if $type == pc{
                width: 50%;
                padding: 130px 50px 80px 80px;
            }
            .num{
                @if $type == ""{
                    @include fontEn;
                    line-height: 1;
                    font-weight: 300;
                }
                @if $type == sp{
                    font-size: 36px;
                }
                @if $type == pc{
                    position: absolute;
                    font-size: 52px;
                    top: 50px;
                    left: 50px;
                }
            }
            .title{
                @if $type == ""{
                    font-weight: bold;
                    line-height: 1.3;
                    margin-top: 0;
                }
                @if $type == sp{
                    font-size: 20px;
                    margin-top: 15px;
                }
                @if $type == pc{
                    font-size: 30px;
                }
            }
            .text{
                @if $type == ""{
                }
                @if $type == sp{
                    margin-top: 20px;
                }
                @if $type == pc{
                    margin-top: 40px;
                }
            }
            .img{
                @if $type == ""{
                    img{
                        max-width: 126px;
                    }
                }
                @if $type == sp{
                    margin-top: 20px;
                }
                @if $type == pc{
                    margin-top: 40px;
                }
            }
            .viewmore{
                @if $type == sp{
                    text-align: right;
                    margin-top: 20px;
                }
                @if $type == pc{
                    text-align: right;
                    padding-right: 40px;
                    position: absolute;
                    bottom: 40px;
                    right: 0px;
                }
                a{
                    @if $type == ""{
                        @include fontEn;
                        color: $c_base;
                        position: relative;
                        display: inline-block;
                        line-height: 1;
                    }
                    @if $type == sp{
                        font-size: 15px;
                        padding-bottom: 10px;
                        padding-right: 35px;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 3px;
                            background-color: $c_base;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transform: scale(1, 1);
                            transform-origin: right top;
                            transition: transform .3s;
                        }
                    }
                    @if $type == pc{
                        font-size: 18px;
                        padding-bottom: 15px;
                        padding-right: 45px;
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 4px;
                            background-color: $c_base;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                        @keyframes lineAnime{
                            0% {
                                width: 0;
                            }

                            100% {
                                width: 100%;
                            }
                        }
                        &:hover{
                            &:before{
                                animation: lineAnime 0.8s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contWhiteBox{
    @if $type == ""{
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        > *:first-child{
            margin-top: 0;
        }
    }
    @if $type == sp{
        padding: 40px 15px 0;
    }
    @if $type == pc{
        padding: 70px 70px 0;
    }
}
* + .contWhiteBox{
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
    }
}

.commonDL{
    @if $type == ""{
    }
    @if $type == sp{
        margin-top: 10px;
    }
    @if $type == pc{
        margin-top: 20px;
    }
    dl{
        @if $type == ""{
            border-bottom: 1px solid #dde2eb;
        }
        @if $type == sp{
            padding: 15px 0;
            display: flex;
        }
        @if $type == pc{
            display: flex;
            padding: 25px 0;
        }
        dt{
            @if $type == ""{
            }
            @if $type == sp{
                width: 25%;
            }
            @if $type == pc{
                padding-left: 15px;
                width: 20%;
            }
        }
        dd{
            @if $type == ""{
            }
            @if $type == sp{
                width: 75%;
                padding-left: 20px;
            }
            @if $type == pc{
                width: 80%;
                padding-left: 50px;
            }
        }
    }
}

.historyWrap{
    @if $type == ""{
        background-color: #eef1f6;
    }
    @if $type == sp{
        margin-top: 30px;
        padding: 30px 20px;
    }
    @if $type == pc{
        margin-top: 50px;
        padding: 50px 60px;
    }
    dl{
        @if $type == ""{
            border-left: 1px solid #dde2eb;
        }
        @if $type == sp{
            padding: 15px 0;
        }
        @if $type == pc{
            display: flex;
            padding: 20px 0;
        }
        dt{
            @if $type == ""{
                font-weight: bold;
                color: $c_base;
                position: relative;
            }
            @if $type == sp{
                padding-left: 20px;
                &:before{
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 2px;
                    background-color: $c_base;
                    position: absolute;
                    left: 0;
                    top: 12px;
                }
            }
            @if $type == pc{
                width: 20%;
                padding-left: 24px;
                &:before{
                    content: "";
                    display: inline-block;
                    width: 14px;
                    height: 2px;
                    background-color: $c_base;
                    position: absolute;
                    left: 0;
                    top: 13px;
                }
            }
        }
        dd{
            @if $type == ""{
            }
            @if $type == sp{
                padding-left: 20px;
                margin-top: 5px;
            }
            @if $type == pc{
                width: 80%;
            }
        }
    }
}

.gMap{
    @if $type == ""{
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
        padding: 0 70px;
    }
    iframe{
        @if $type == ""{
            width: 100%;
        }
        @if $type == sp{
            height: 250px;
        }
        @if $type == pc{
            height: 350px;
        }
    }
}

.productList{
    @if $type == ""{
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
    }
    .productListItem{
        @if $type == ""{
        }
        @if $type == sp{
            + .productListItem{
                margin-top: 30px;
            }
        }
        @if $type == pc{
            width: calc(50% - 40px);
            &:nth-of-type(even){
                margin-left: 80px;
            }
            &:nth-of-type(n + 3){
                margin-top: 80px;
            }
        }
        a{
            @if $type == ""{
                display: block;
            }
            @if $type == sp{
            }
            @if $type == pc{
                &:hover{
                    .title:after{
                        right: 0;
                    }
                }
            }
        }
        figure{
            @if $type == ""{
                box-shadow: 0px 6px 6px 4px rgba(23,72,156,0.1);
                margin: 0;
            }
            @if $type == sp{
            }
            @if $type == pc{
            }
        }
        .textWrap{
            @if $type == ""{
            }
            @if $type == sp{
                margin-top: 15px;
                padding-left: 15px;
            }
            @if $type == pc{
                margin-top: 30px;
                padding-left: 30px;
            }
            .title{
                @if $type == ""{
                    margin: 0;
                    color: $c_base;
                    font-weight: bold;
                    position: relative;
                    line-height: 1.5;
                }
                @if $type == sp{
                    font-size: 18px;
                    padding-right: 40px;
                    &:after{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/icon_arrow.svg) no-repeat;
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        right: 15px;
                        bottom: 5px;
                        transition: .3s;
                    }
                }
                @if $type == pc{
                    font-size: 22px;
                    padding-right: 70px;
                    &:after{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/icon_arrow.svg) no-repeat;
                        width: 22px;
                        height: 22px;
                        position: absolute;
                        right: 30px;
                        bottom: 5px;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

.productDetailMain{
    @if $type == ""{
    }
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
    }
    .productDetailMainText{
        @if $type == ""{
            position: relative;
        }
        @if $type == sp{
            text-align: center;
        }
        @if $type == pc{
            width: 47%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 30px;
        }
        .tag{
            @if $type == ""{
            }
            @if $type == sp{
                margin-bottom: 20px;
                text-align: left;
            }
            @if $type == pc{
                position: absolute;
                top: 0;
                left: 0;
            }
            span{
                @if $type == ""{
                    line-height: 1.3;
                    display: inline-block;
                    font-weight: bold;
                    border: 2px solid $c_base;
                    color: $c_base;
                    position: relative;
                    text-align: center;
                    + span{
                        margin-left: 3px;
                    }
                }
                @if $type == sp{
                    font-size: 12px;
                    padding: 3px 30px;
                    &:before{
                        content: "";
                        display: inline-block;
                        background-color: $c_base;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                    }
                }
                @if $type == pc{
                    font-size: 14px;
                    padding: 3px 40px;
                    &:before{
                        content: "";
                        display: inline-block;
                        background-color: $c_base;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                    }
                }
            }
        }
        .mainTitle{
            @if $type == ""{
                color: $c_base;
                font-weight: bold;
                line-height: 1.3;
                img{
                    max-width: 220px;
                }
            }
            @if $type == sp{
                font-size: 36px;
                + p{
                    font-size: 16px;
                    color: #9ea4ad;
                    margin-top: 20px;
                }
            }
            @if $type == pc{
                font-size: 48px;
                + p{
                    font-size: 18px;
                    color: #9ea4ad;
                    margin-top: 30px;
                }
            }
        }
    }
    figure{
        @if $type == ""{
            box-shadow: 0px 6px 6px 4px rgba(23,72,156,0.1);
            margin: 0;
        }
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            width: 53%;
        }
    }
}

.anchorList{
    @if $type == ""{
        background-color: #e6ebf5;
        display: flex;
        flex-wrap: wrap;
    }
    @if $type == sp{
        padding: 15px 10px;
        margin-top: 40px;
        justify-content: space-between;
    }
    @if $type == pc{
        padding: 15px 5%;
        margin-top: 80px;
        justify-content: space-between;
    }
    a{
        @if $type == ""{
            color: $c_base;
            font-weight: bold;
            display: inline-block;
            position: relative;
        }
        @if $type == sp{
            font-size: 14px;
            padding-right: 20px;
            margin: 5px 13px;
            width: calc(50% - 30px);
            &:after{
                content: '';
                width: 6px;
                height: 6px;
                border: 0px;
                border-bottom: solid 2px $c_base;
                border-right: solid 2px $c_base;
                transform: rotate(45deg);
                position: absolute;
                top: calc(50% - 5px);
                right: 0px;
                transition: .3s;
            }
        }
        @if $type == pc{
            font-size: 14px;
            padding-right: 20px;
            &:hover{
                &:after{
                    top: calc(50%);
                }
            }
            + a{
                margin-left: 5%;
            }
            &:after{
                content: '';
                width: 6px;
                height: 6px;
                border: 0px;
                border-bottom: solid 2px $c_base;
                border-right: solid 2px $c_base;
                transform: rotate(45deg);
                position: absolute;
                top: calc(50% - 5px);
                right: 0px;
                transition: .3s;
            }
        }
    }
}

.meritBox{
    @if $type == ""{
        box-shadow: 0px 8px 6px 2px rgba(23,72,156,0.1);
        position: relative;
    }
    @if $type == sp{
        padding: 40px 20px 30px;
    }
    @if $type == pc{
        padding: 50px 50px 50px;
    }
    .meritTitle{
        @if $type == ""{
            color: $c_base;
            position: absolute;
            font-weight: normal;
            letter-spacing: .1em;
        }
        @if $type == sp{
            font-size: 20px;
            padding-left: 30px;
            top: -0.5em;
            left: 15px;
            span{
                @include fontEn;
                font-size: 16px;
                letter-spacing: normal;
            }
            &:before{
                content: "";
                display: inline-block;
                background-color: $c_base;
                width: 18px;
                height: 2px;
                position: absolute;
                top: 14px;
                left: 0;
            }
        }
        @if $type == pc{
            font-size: 26px;
            padding-left: 30px;
            top: -0.5em;
            left: 25px;
            span{
                @include fontEn;
                font-size: 22px;
                letter-spacing: normal;
            }
            &:before{
                content: "";
                display: inline-block;
                background-color: $c_base;
                width: 20px;
                height: 2px;
                position: absolute;
                top: 18px;
                left: 0;
            }
        }
    }
    .meritBoxList{
        @if $type == ""{
        }
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
            flex-wrap: wrap;
        }
        .meritBoxItem{
            @if $type == ""{
            }
            @if $type == sp{
                position: relative;
                padding-top: 90px;
                + .meritBoxItem{
                    margin-top: 20px;
                    padding-top: 110px;
                    border-top: 2px solid #e6ecf5;
                    .textWrap figure{
                        top: 20px;
                    }
                }
            }
            @if $type == pc{
                width: calc(50% - 25px);
                &:nth-of-type(even){
                    margin-left: 50px;
                }
                &:nth-of-type(n + 3){
                    margin-top: 40px;
                    padding-top: 40px;
                    border-top: 2px solid #e6ecf5;
                }
            }
            h4{
                @if $type == ""{
                    font-weight: bold;
                    color: $c_base;
                }
                @if $type == sp{
                    font-size: 16px;
                    text-align: center;
                }
                @if $type == pc{
                    font-size: 18px;
                }
            }
            .textWrap{
                @if $type == ""{
                }
                @if $type == sp{
                }
                @if $type == pc{
                    margin-top: 15px;
                    display: flex;
                }
                p{
                    @if $type == ""{
                    }
                    @if $type == sp{
                        margin-top: 10px;
                    }
                    @if $type == pc{
                        width: 64%;
                    }
                }
                figure{
                    @if $type == ""{
                        text-align: center;
                        margin: 0;
                    }
                    @if $type == sp{
                        position: absolute;
                        top: 0px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        img{
                            max-height: 50px;
                        }
                    }
                    @if $type == pc{
                        width: 36%;
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}

.surunaraArrow{
    @if $type == ""{
        font-weight: bold;
        background: url(../images/second/surunara_arrow.png) no-repeat center bottom;
        text-align: center;
    }
    @if $type == sp{
        font-size: 20px;
        margin-top: 40px;
        padding-bottom: 40px;
    }
    @if $type == pc{
        font-size: 26px;
        margin-top: 50px;
        padding-bottom: 40px;
    }
}

.numTitle{
    @if $type == ""{
        display: flex;
        align-items: center;
    }
    @if $type == sp{
        margin-top: 30px;
        + p{
            margin-top: 15px;
        }
    }
    @if $type == pc{
        margin-top: 50px;
        + p{
            margin-top: 30px;
        }
    }
    span{
        @if $type == ""{
            display: inline-block;
            @include fontEn;
            font-weight: 600;
            color: $c_base;
            line-height: 1;
            margin-top: 5px;
            flex:none;
        }
        @if $type == sp{
            font-size: 20px;
            padding-right: 15px;
        }
        @if $type == pc{
            font-size: 20px;
            padding-right: 15px;
        }
    }
    h3{
        @if $type == ""{
            font-weight: normal;
            border-left: 2px solid #e6ecf5;
        }
        @if $type == sp{
            font-size: 18px;
            padding-left: 15px;
        }
        @if $type == pc{
            font-size: 22px;
            padding-left: 15px;
        }
    }
}

.viewmore{
    @if $type == sp{
        text-align: right;
        margin-top: 10px;
    }
    @if $type == pc{
        text-align: right;
        margin-top: 10px;
    }
    a{
        @if $type == ""{
            @include fontEn;
            color: $c_base;
            position: relative;
            display: inline-block;
            line-height: 1;
            font-weight: 500;
        }
        @if $type == sp{
            font-size: 15px;
            padding-bottom: 10px;
            padding-right: 35px;
            &:before{
                content: "";
                display: inline-block;
                width: 100%;
                height: 2px;
                background-color: $c_base;
                position: absolute;
                bottom: 0;
                left: 0;
                transform: scale(1, 1);
                transform-origin: right top;
                transition: transform .3s;
            }
        }
        @if $type == pc{
            font-size: 16px;
            padding-bottom: 10px;
            padding-right: 40px;
            &:before{
                content: "";
                display: inline-block;
                width: 100%;
                height: 2px;
                background-color: $c_base;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            @keyframes lineAnime{
                0% {
                    width: 0;
                }

                100% {
                    width: 100%;
                }
            }
            &:hover{
                &:before{
                    animation: lineAnime 0.8s;
                }
            }
        }
    }
}

.shadowBox{
    @if $type == ""{
        box-shadow: 0px 8px 6px 2px rgba(23,72,156,0.1);
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
    }
    .blueTitle{
        @if $type == ""{
            background-color: $c_base;
            color: #fff;
        }
    }
    .cont{
        @if $type == sp{
            padding: 20px 20px;
        }
        @if $type == pc{
            padding: 30px 50px;
        }
    }
}

.btnTwoCol{
    @if $type == sp{
        > div{
            + div{
                margin-top: 20px;
            }
        }
    }
    @if $type == pc{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        > div{
            width: calc(50% - 20px);
            .commonBtn{
                width: 100%;
            }
        }
    }
}

.qaBox{
    @if $type == ""{
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
    dl{
        @if $type == ""{
        }
        @if $type == sp{
            + dl{
                margin-top: 30px;
            }
        }
        @if $type == pc{
            + dl{
                margin-top: 40px;
            }
        }
        dt{
            @if $type == ""{
                font-weight: bold;
                color: $c_base;
            }
            @if $type == sp{
                font-size: 15px;
            }
            @if $type == pc{
                font-size: 18px;
            }
        }
        dd{
            @if $type == ""{
            }
            @if $type == sp{
                margin-top: 15px;
            }
            @if $type == pc{
                margin-top: 20px;
            }
        }
    }
}

.wp-pagenavi{
    @if $type == ""{
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 1;
    }
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 70px;
    }
    .page,.nextpostslink,.previouspostslink,.extend,.last,.first{
        @if $type == ""{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $c_base;
            text-decoration: none;
            font-weight: bold;
            position: relative;
            @include fontEn;
            padding-top: 3px;
        }
        @if $type == sp{
            min-width: 30px;
            min-height: 30px;
            margin: 0 5px;
        }
        @if $type == pc{
            font-size: 20px;
            min-width: 38px;
            min-height: 38px;
            margin: 0 5px;
            &:hover{
                background-color: $c_base;
                color: #fff;
            }
        }
    }
    .extend{
        &:hover{
            background: none!important;
            color: $c_base!important;
        }
    }
    span.current{
        @if $type == ""{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: normal;
            position: relative;
            background-color: $c_base;
            color: #fff;
            @include fontEn;
            padding-top: 3px;
        }
        @if $type == sp{
            min-width: 30px;
            min-height: 30px;
            margin: 0 5px;
        }
        @if $type == pc{
            font-size: 20px;
            min-width: 38px;
            min-height: 38px;
            margin: 0 5px;
        }
    }
    .previouspostslink, .nextpostslink{
        @if $type == ""{
            color: $c_base;
            border: none;
            position: relative;
            &:hover{
                background: none!important;
                opacity: .7;
            }
        }
    }
    .previouspostslink:before{
        @if $type == ""{
            content: "";
            box-sizing: border-box;
            width: 3px;
            height: 3px;
            border: 3px solid transparent;
            border-top: 3px solid $c_base;
            border-left: 3px solid $c_base;
            position: absolute;
            transform: rotate(-45deg);
        }
        @if $type == sp{
            top: calc(50% - 2px);
            left: 50%;
        }
        @if $type == pc{
            top: calc(50% - 2px);
            left: 50%;
        }
    }
    .nextpostslink:before{
        @if $type == ""{
            content: "";
            box-sizing: border-box;
            width: 3px;
            height: 3px;
            border: 3px solid transparent;
            border-bottom: 3px solid $c_base;
            border-right: 3px solid $c_base;
            position: absolute;
            transform: rotate(-45deg);
        }
        @if $type == sp{
            top: calc(50% - 2px);
            right: 50%;
        }
        @if $type == pc{
            top: calc(50% - 2px);
            right: 50%;
        }
    }
}//wp-pagenavi

.postYmd{
    @if $type == ""{
        display: flex;
        align-items: center;
    }
    @if $type == sp{
        margin-top: 10px;
        padding-left: 38px;
    }
    @if $type == pc{
        margin-top: 20px;
        padding-left: 38px;
    }
    .ymd{
        @if $type == ""{
            line-height: 1;
            @include fontEn;
            padding-top: 3px;
        }
        @if $type == sp{
            display: inline-block;
            vertical-align: middle;
        }
        @if $type == pc{
        }
        .year{
            @if $type == ""{
            }
            @if $type == sp{
                font-size: 13px;
            }
            @if $type == pc{
                font-size: 18px;
            }
        }
        .monthday{
            @if $type == ""{
            }
            @if $type == sp{
                font-size: 19px;
            }
            @if $type == pc{
                font-size: 28px;
            }
        }
    }
    .tag{
        @if $type == ""{
            display: inline-block;
            text-align: center;
            color: $c_base;
            border-radius: 20px;
            background-color: #e6ebf5;
            line-height: 1.3;
            margin-left: 20px;
        }
        @if $type == sp{
            font-size: 12px;
            padding: 5px 20px;
            margin-left: 15px;
            vertical-align: middle;
        }
        @if $type == pc{
            min-width: 120px;
            flex: none;
            font-size: 13px;
            padding: 10px 10px;
        }
    }
}

//form
.contact_wrap{
    @if $type == ""{
        input,
        button,
        select,
        textarea {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: transparent;
            border: none;
            border-radius: 0;
            font: inherit;
            outline: none;
            background-color: #fff;
        }

        textarea {
            resize: vertical;
        }

        input[type='checkbox'],
        input[type='radio'] {
            display: none;
        }

        input[type='submit'],
        input[type='button'],
        label,
        button,
        select {
            cursor: pointer;
        }

        select::-ms-expand {
            display: none;
        }

        input,textarea {
            border: 1px solid #D1D1D1;
            width: 100%;
        }
    }
    @if $type == sp{
    }
    @if $type == pc{
    }

    input {
        @if $type == ""{
        }
        @if $type == sp{
            padding: 10px 10px;
            &.sizeS{
                width: 30%;
            }
            &.sizeM{
                width: 50%;
            }
        }
        @if $type == pc{
            padding: 15px 20px;
            &.sizeS{
                width: 20%;
            }
            &.sizeM{
                width: 40%;
            }
        }
        &::placeholder {
            color: #D1D1D1;
        }
    }

    select {
        @if $type == ""{
            border: 1px solid #D1D1D1;
            padding: 10px;
            background: url(../images/common/arrow_btm.png) no-repeat right 15px center;
            padding-right: 30px;
        }
        @if $type == sp{
            &.sizeS{
                width: 30%;
            }
            &.sizeM{
                width: 50%;
            }
        }
        @if $type == pc{
            &.sizeS{
                width: 20%;
            }
            &.sizeM{
                width: 40%;
            }
        }
        &::placeholder {
            color: #D1D1D1;
        }
    }

    textarea {
        @if $type == ""{
            margin: 10px 0 0;
            width: 100%;
            height: 10em;
        }
        @if $type == sp{
            padding: 10px;
        }
        @if $type == pc{
            padding: 15px 20px;
        }
    }
    > dl{
        @if $type == sp{
            &:not(:first-of-type){
                margin-top: 20px;
            }
        }
        @if $type == pc{
            display: flex;
            &:not(:first-of-type){
                margin-top: 40px;
            }
        }
        > dt{
            @if $type == ""{
                font-weight: bold;
            }
            @if $type == sp{
                margin-bottom: 5px;
            }
            @if $type == pc{
                width: 270px;
                padding-right: 50px;
                //padding-top: 16px;
            }
            .hissu{
                background-color: red;
                color: #fff;
                font-size: 12px;
                padding: 5px 5px;
                line-height: 1;
                display: inline-block;
                margin-left: 10px;
            }
        }
        > dd{
            @if $type == sp{
            }
            @if $type == pc{
                width: calc(100% - 270px);
            }
            .attention{
                display: block;
                font-size: 12px;
                margin-top: 5px;
            }
            > dl{
                @if $type == sp{
                    &:not(:first-of-type){
                        margin-top: 10px;
                    }
                }
                @if $type == pc{
                    &:not(:first-of-type){
                        margin-top: 20px;
                    }
                }
                > dt{
                    @if $type == sp{
                        margin-bottom: 5px;
                    }
                    @if $type == pc{
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                }
                > dd{
                    @if $type == sp{
                    }
                    @if $type == pc{
                    }
                }
            }
        }
        //添付ファイルアップロード用
        &.uploadItem{
            > dd{
            position: relative;
                .mwform-file-delete{
                    position: absolute;
                    transform: translateY(-50%);
                    @if $type == sp {
                        top: calc(50% - 10px);
                        right: 10px;
                    }
                    @if $type == pc {
                        top: calc(50% - 12px);
                        right: 20px;
                    }
                }
            }
        }
    }
    .btn_wrap{
        @if $type == ""{
            text-align: center;
        }
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 40px;
        }
        .commonBtn{
            @if $type == ""{
                background-color: #fff;
                border-radius: 30px;
            }
            @if $type == sp{
                + .commonBtn{
                    margin-top: 20px;
                }
                &.back{
                    width: 50%;
                }
            }
            @if $type == pc{
                &:hover{
                    background-color: $c_base;
                    color: #fff;
                }
                + .commonBtn{
                    margin-left: 20px;
                }
                &.back{
                    min-width: 200px;
                }
            }
        }
    }
    .agreeBox {
        @if $type == ""{
            margin-top: 20px;
        }
        @if $type == pc{
            margin-top: 40px;
        }

        .agreeBoxNotes {
            @if $type == ""{
                margin-top: 0;
                margin-bottom: 20px;
            }
            @if $type == pc{
                text-align: center;
            }

            a {
                color: $c_text;
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }

        .agreeBoxCheck {
            @if $type == ""{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                label {
                    font-weight: bold;

                    input[type="checkbox"] {
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        border: 1px solid #000;
                        margin-right: 10px;
                        background: #fff;
                        padding: 0;
                        transform: translateY(3px);
                        &:checked:before {
                            opacity: 1;
                        }

                        &:before {
                            border-right: 3px solid $c_base;
                            border-bottom: 3px solid $c_base;
                            content: '';
                            display: block;
                            height: 9px;
                            left: 5px;
                            margin-top: -7px;
                            opacity: 0;
                            position: absolute;
                            top: 50%;
                            transform: rotate(45deg);
                            width: 5px;
                        }
                    }
                }
            }
        }
        .error{
            @if $type == ""{
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }
}
* + .tabLinkBtn{
    @if $type == sp {
        margin-top: 30px;
    }
    @if $type == pc {
        margin-top: 40px;
    }
}
.tabLinkBtn{
    @if $type == "" {
        display: flex;
        justify-content: center;
    }
    @if $type == sp {
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
    }
    @if $type == pc {
        margin-bottom: 40px;
    }
    li{
        @if $type == sp {
            line-height: 1.4;
            &:not(:last-child){
                margin-right: 8px;
            }
            // &+li{
            //     margin-top: 5px;
            // }
        }
        @if $type == pc {
            &:not(:last-child){
                margin-right: 64px;
            }
        }
        &.now{
            @if $type == "" {
                a{
                    span{
                        border-bottom-color: $c_base;
                        color: $c_base;
                        font-weight: bold;
                    }
                }
            }
        }
        a{
            @if $type == "" {
                display: block;
            }
            @if $type == sp {
                text-align: center;
            }
            span{
                @if $type == "" {
                    border-bottom: 3px solid transparent;
                    display: inline-block;
                    text-align: center;
                    transition: .3s border;
                }
                @if $type == sp {
                    font-size: 14px;
                    padding: 0 8px 8px;
                    text-align: left;
                }
                @if $type == pc {
                    font-size: 18px;
                    padding: 10px 34px;
                    &:hover{
                        border-bottom-color: $c_base;
                        color: $c_base;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

// For Download Manager Plugin.
.list-group {
    .list-group-item{
        @if $type == ""{
            &::before{
                content: none;
            }
        }
    }
}

// For WP-Members Plugin.
#wpmem_login_form {
    // .buttons{
    //     @if $type == ""{
    //         background-color: #17489c;
    //         border: none;
    //         border-radius: 50px;
    //         color: #fff;
    //         cursor: pointer;
    //         padding: 6px 12px;
    //     }
    // }
    .link-text{
        a{
            @if $type == ""{
                color: #337ab7;
            }
        }
    }
}
.button_div{
    .buttons{
        @if $type == ""{
            background-color: #17489c;
            border: none;
            border-radius: 50px;
            color: #fff;
            cursor: pointer;
            padding: 6px 12px;
        }
    }
}

.wpmemCustomWrap{
    p{
        @if $type == "" {
            margin-bottom: 20px;
        }
    }
    a{
        @if $type == "" {
            color: #6f99d9;
        }
    }
    ul:not([class]){
        @if $type == "" {
            margin-top: 30px;
            margin-bottom: 10px;
        }
        @if $type == sp{
            > li{
                padding-left: 22px;
                position: relative;
    
                &:not(:first-of-type){
                    margin-top: 2px;
                }
    
                &:before{
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 2px;
                    background-color: $c_base;
                    position: absolute;
                    left: 5px;
                    top: 11px;
                }
                > ul{
                    margin-top: 10px;
                    padding-left: 3px;
                    li{
                        padding-left: 13px;
                        position: relative;
                        color: #828b99;
    
                        &:not(:first-of-type){
                            margin-top: 10px;
                        }
    
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            background-color: #828b99;
                            position: absolute;
                            left: 0px;
                            top: 11px;
                        }
                    }
                }
            }
        }
        @if $type == pc{
            > li{
                padding-left: 40px;
                position: relative;
    
                &:not(:first-of-type){
                    margin-top: 2px;
                }
    
                &:before{
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 2px;
                    background-color: $c_base;
                    position: absolute;
                    left: 15px;
                    top: 14px;
                }
                > ul{
                    margin-top: 15px;
                    padding-left: 10px;
                    li{
                        padding-left: 15px;
                        position: relative;
                        color: #828b99;
    
                        &:not(:first-of-type){
                            margin-top: 10px;
                        }
    
                        &:before{
                            content: "";
                            display: inline-block;
                            width: 4px;
                            height: 4px;
                            background-color: #828b99;
                            position: absolute;
                            left: 0px;
                            top: 13px;
                        }
                    }
                }
            }
        }
    }
}

.sample{
    @if $type == ""{
    }
    @if $type == sp{
    }
    @if $type == pc{
    }
}

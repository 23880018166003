//================================================
// ★サイト内共通で使うスタイルとブロックエディタで使うスタイルをmixinで管理★
// 使用例としては、
// サイト内共通で使う「h2.title」と
// ブロックエディタで使う「.singleContents h2」を
// 共通でソース管理する　という感じ
// 共通で管理しきれないものは無理に共通化しない
// ボタンやテーブルは共通化していない
// 以下mixinとクラス「.singleContents」の中身に変更を加えたら、
// 「_gb_style.scss」にも反映する
//フロント側・・・.singleContents
// エディタ側・・・.editor-styles-wrapper.block-editor-writing-flow
//================================================
@mixin pMargin {
    @if $type == sp{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @if $type == pc{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
@mixin h2TitleMargin {
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 80px;
    }
}
@mixin h3TitleMargin {
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}
@mixin h4TitleMargin {
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 60px;
    }
}
@mixin titleAfterPMargin {
    @if $type == sp{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @if $type == pc{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
@mixin h2Title {
    @if $type == ""{
        font-weight: bold;
        position: relative;
        border-bottom: 2px solid #e6ecf5;
    }
    @if $type == sp{
        font-size: 18px;
        padding-left: 24px;
        padding-bottom: 12px;
    }
    @if $type == pc{
        font-size: 26px;
        padding-left: 36px;
        padding-bottom: 15px;
    }
    &:after{
        @if $type == ""{
            content: "";
            display: block;
            background-color: $c_base;
            position: absolute;
            border-radius: 50%;
        }
        @if $type == sp{
            width: 4px;
            height: 4px;
            top: 11px;
            left: 5px;
        }
        @if $type == pc{
            width: 6px;
            height: 6px;
            top: 15px;
            left: 9px;
        }
    }
}
@mixin h2Title2 {
    @if $type == ""{
        font-weight: bold;
        color: $c_base;
        position: relative;
    }
    @if $type == sp{
        font-size: 20px;
        padding-left: 30px;
    }
    @if $type == pc{
        font-size: 30px;
        padding-left: 38px;
    }
    &:after{
        @if $type == ""{
            content: "";
            display: block;
            background-color: $c_base;
            position: absolute;
        }
        @if $type == sp{
            width: 18px;
            height: 3px;
            top: 13px;
            left: 0;
        }
        @if $type == pc{
            width: 20px;
            height: 3px;
            top: 21px;
            left: 0;
        }
    }
}
@mixin h3Title {
    @if $type == ""{
        font-weight: 400;
        border-left: 2px solid #e6ecf5;
    }
    @if $type == sp{
        font-size: 18px;
        padding-left: 15px;
    }
    @if $type == pc{
        font-size: 22px;
        padding-left: 22px;
    }
}
@mixin h4Title {
    @if $type == ""{
        font-weight: bold;
        color: $c_base;
    }
    @if $type == sp{
        font-size: 16px;
    }
    @if $type == pc{
        font-size: 18px;
    }
}
@mixin commonList {
    @if $type == sp{
        > li{
            padding-left: 22px;
            position: relative;

            &:not(:first-of-type){
                margin-top: 10px;
            }

            &:before{
                content: "";
                display: inline-block;
                width: 8px;
                height: 2px;
                background-color: $c_base;
                position: absolute;
                left: 5px;
                top: 11px;
            }
            > ul{
                margin-top: 10px;
                padding-left: 3px;
                li{
                    padding-left: 13px;
                    position: relative;
                    color: #828b99;

                    &:not(:first-of-type){
                        margin-top: 10px;
                    }

                    &:before{
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: #828b99;
                        position: absolute;
                        left: 0px;
                        top: 11px;
                    }
                }
            }
        }
    }
    @if $type == pc{
        > li{
            padding-left: 40px;
            position: relative;

            &:not(:first-of-type){
                margin-top: 10px;
            }

            &:before{
                content: "";
                display: inline-block;
                width: 12px;
                height: 2px;
                background-color: $c_base;
                position: absolute;
                left: 15px;
                top: 14px;
            }
            > ul{
                margin-top: 15px;
                padding-left: 10px;
                li{
                    padding-left: 15px;
                    position: relative;
                    color: #828b99;

                    &:not(:first-of-type){
                        margin-top: 10px;
                    }

                    &:before{
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: #828b99;
                        position: absolute;
                        left: 0px;
                        top: 13px;
                    }
                }
            }
        }
    }
    
}
@mixin olList {
    @if $type == ""{
        counter-reset: item;
        list-style-type: none;
        padding-left: 0;
        li{
            position: relative;
            &:before{
                counter-increment: item;
                content: counter(item);
                font-weight: bold;
                color: $c_base;
                @include fontEn;
                font-weight: 600;
            }
            &:not(:first-of-type){
                margin-top: 10px;
            }
        }
    }
    @if $type == sp{
        li{
            padding-left: 22px;
            &:before{
                position: absolute;
                left: 5px;
                top: 0px;
                font-size: 16px;
            }
        }
    }
    @if $type == pc{
        li{
            padding-left: 40px;
            &:before{
                position: absolute;
                left: 18px;
                top: 0;
                font-size: 19px;
            }
        }
    }
}
ol.hangingListAb {
    @if $type == ""{
        list-style-type: lower-alpha;
        list-style-position: inside;
    }

    li {
        @if $type == ""{
            &::before{
            content: none;
            }
            &::marker{
            font-weight: bold;
            color: #17489c;
            font-family: 'Teko', sans-serif;
            font-weight: 600;
            }
        }
        @if $type == sp{
            padding-left: 22px;
        }
        @if $type == pc{
            padding-left: 40px;
        }
    }
}
@mixin colorBox{
    @if $type == ""{
        background-color: #dadee5;
        .cont p{
            margin: 0;
        }
    }
    @if $type == sp{
        padding: 30px;
        p.title{
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            @include fontEn;
        }
        .cont * + p{
            margin: 20px 0 0;
        }
    }
    @if $type == pc{
        padding: 50px 50px;
        p.title{
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 10px;
            @include fontEn;
        }
        .cont * + p{
            margin: 30px 0 0;
        }
    } 
}



//================================================
// ★ブロックエディタで使うCSS★
// フロント側用のクラス「.singleContents」
//================================================
// ▼_gb_style.scssにコピペ ここから▼
.singleContents{
    .has-inline-color.has-luminous-vivid-orange-color{
        color: $c_base!important;
    }
    .inner{
        & > *:first-child{
            margin-top: 0;
        }
    }
//================================================
// 余白
//================================================
    p{
        @if $type == sp {
            font-size: 14px;
        }
        @if $type == pc {
            font-size: 16px;
        }
    }
    
    // * + p {
    //     @include pMargin;
    // }
    p {
        @include pMargin;
    }
     p:first-child {
        @if $type == "" {
        margin-top: 0;
        }
    }
    .wp-block-media-text {
        p:first-child {
            @if $type == pc {
                margin-top: 40px;
                }
            @if $type == sp {
                margin-top: 20px;
                }
        }
    }
    h2 + p,h3 + p,h4 + p{
        @include titleAfterPMargin;
    }
    * + h2{
        @include h2TitleMargin;
    }
    * + h3{
        @include h3TitleMargin;
    }
    * + h4{
        @include h4TitleMargin;
    }
    * + h2,
    * + h3,
    * + h4 {
        @if $type == sp {
            margin-bottom: 20px;
        }
        @if $type == pc {
            margin-bottom: 30px;
        }
    }
    * + h4 {
        @if $type == sp {
            margin-bottom: 15px;
        }
        @if $type == pc {
            margin-bottom: 15px;
        }
    }

    //================================================
    // タイトル
    //================================================
    h2{
        @include h2Title;
    }
    h3{
        @include h3Title;
    }
    h4{
        @include h4Title;
    }
    
    //================================================
    // リスト
    //================================================
    ul{
        @include commonList;
    }
    ol{
        @include olList;
    }
    * + ul, * + ol{
        @if $type == sp{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @if $type == pc{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    * + ol{
        @if $type == sp{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @if $type == pc{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }


ol.hangingListAb {
    @if $type == ""{
        list-style-type: lower-alpha;
        list-style-position: inside;
    }

    li {
        @if $type == ""{
            &::before{
            content: none;
            }
            &::marker{
            font-weight: bold;
            color: #17489c;
            font-family: 'Teko', sans-serif;
            font-weight: 600;
            }
        }
        @if $type == sp{
            padding-left: 22px;
        }
        @if $type == pc{
            padding-left: 40px;
        }
    }
}

//================================================
// 既存ブロック（オーバーライド）
//================================================
    p + .wp-block-gallery{
        margin-top: 10px;
    }
// ボタン
    .wp-block-button{
        @if $type == sp{
            margin-bottom: 20px;
        }
        @if $type == pc{
            margin-bottom: 40px;
        }
        a, .wp-block-button__link{
            @if $type == ""{
                display: inline-block;
                text-align: center;
                position: relative;
                background-color: #fff;
                border-radius: 30px;
                color: $c_base;
                box-shadow: 0px 3px 6px 4px rgba(23,72,156,0.1);
            }
            @if $type == sp{
                font-size: 14px;
                padding: 15px 30px;
                width: 100%;
            }
            @if $type == pc{
                font-size: 17px;
                padding: 15px 30px;
                min-width: 320px;
                transition: .3s;
                &:hover{
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
        .wp-block-button__link{
            @if $type == ""{
                color: $c_base;
                background-color: #fff;
            }
            @if $type == pc{
                &:hover{
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
        &.is-style-outline{
            .wp-block-button__link{
                @if $type == ""{
                    background-color: #fff;
                }
                @if $type == pc{
                    &:hover{
                        background-color: $c_base;
                        color: #fff;
                    }
                }
            }
        }
    }
    .wp-block-buttons{
        @if $type == sp{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @if $type == pc{
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .wp-block-button{
            @if $type == sp{
                display: block;
                margin-bottom: 0;
                width: 100%;
            }
            @if $type == pc{
                margin-bottom: 0;
            }
        }
    }
// テーブル
    .wp-block-table{
        @if $type == "" {
            width: 100%;
            border-collapse: collapse;
            border-spacing: inherit;
            border-top: 1px solid $c_base;
            border-left: 1px solid $c_base;
        }
        thead{
            @if $type == "" {
                border-bottom: none;
            }
        }
        tr{
            th{
                @if $type == ""{
                    border: none;
                    font-weight: bold;
                    border-bottom: 1px solid $c_base;
                    border-right: 1px solid $c_base;
                    background-color: $c_base;
                    color: #fff;
                    text-align: left;
                    &.sub{
                        background-color: #d1e2ff;
                        color: $c_text;
                    }
                }

                @if $type == sp{
                    padding: 10px 20px;
                }
                @if $type == pc{
                    padding: 10px 30px;
                    font-size: 14px;
                }

                &.null{
                    border-top: 1px solid $c_base;
                    border-left: 1px solid $c_base;
                    background-color: #fff;
                }
            }
            td{
                @if $type == ""{
                    border: none;
                    border-bottom: 1px solid $c_base;
                    border-right: 1px solid $c_base;
                    word-break: break-all;
                    &.bdb{
                        border-bottom: 1px solid #dde2eb;
                    }
                    &.bdr{
                        border-right: 1px solid #dde2eb;
                    }
                    &.sub{
                        background-color: #d1e2ff;
                        color: $c_text;
                    }
                }

                @if $type == sp{
                    padding: 15px 20px;
                }
                @if $type == pc{
                    padding: 15px 30px;
                    font-size: 14px;
                }

                &.null{
                    border-top: 1px solid $c_base;
                    border-left: 1px solid $c_base;
                }
            }
        }
        &.customTable {
            @if $type == "" {
                th {
                    background-color: #e6ebf5 !important;
                    color: $c_base !important;
                }
            }
            @if $type == sp{}

            @if $type == pc{
                th {
                    width: 250px;
                }
                td {
                    width: calc(100% - 250px);
                }
            }

        }	
    }
    .wp-block-image{
        @if $type == "" {
        }
        @if $type == sp{
            margin-bottom: 20px;
        }
        @if $type == pc{
            margin-bottom: 40px;
        }
    }
    .wp-block-columns{
        .wp-block-image{
            @if $type == "" {
                margin-bottom: 10px;
            }
        }
    }
// メディアとテキスト
    .wp-block-media-text .wp-block-media-text__content{
        @if $type == sp {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }
        p{
            @if $type == "" {
                margin-top: 0;
            }
        }
    }
    .wp-block-media-text{
        @if $type == sp {
            margin-bottom: 20px;
        }
        @if $type == pc {
            margin-bottom: 30px;
        }
    }
// グループ
    .wp-block-group {
        @if $type == sp {
            margin-bottom: 20px;
        }
        @if $type == pc {
            margin-bottom: 30px;
        }
    }
        
//================================================
// カスタムブロック
//================================================
.colorBox{
    @include colorBox;
}


}
// ▲_gb_style.scssにコピペ ここまで▲
// ブロックエディタ用の記述ここまで



//================================================
// ★サイト内共通で使うCSS★
//================================================

//================================================
// 余白
//================================================
* + p {
    @if $type == sp{
        margin-top: 20px;
        &.wide{
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    @if $type == pc{
        margin-top: 30px;
        &.wide{
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
}

h2,h3,h4{
    line-height: 1.3;
}

h2 + p,h3 + p{
    @include titleAfterPMargin;
}

* + h2.title, * + .h2, * + h2.title2{
    @include h2TitleMargin;
}

* + h3.title {
    @include h3TitleMargin;
}
* + h4.title {
    @include h4TitleMargin;
}

h4 + p{
    margin-top: 15px;
}

//================================================
// タイトル
//================================================
h2.title{
    @include h2Title;
}
h2.title2{
    @include h2Title2;
}
h3.title{
    @include h3Title;
}
h4.title{
    @include h4Title;
}

//================================================
// リスト
//================================================
ul.commonList{
    @include commonList;
}

ol{
    @include olList;
}

//アキ調整
section + section{
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 80px;
    }
}

* + .commonList, * + .linkList , * + .colorBox,  * + .borderBox, * + .commonTable{
    @if $type == sp{
        margin-top: 20px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

* + .borderBox{
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 60px;
    }
}

* + ol {
    @if $type == sp{
        margin-top: 20px;
    }
    @if $type == pc{
        margin-top: 30px;
    }
}

* + .imgLine2, * + .imgLine3, * + .twoCol{
    @if $type == sp{
        margin-top: 20px;
    }
    @if $type == pc{
        margin-top: 30px;
    }
}

a.icon{
    display: inline-block;
    position: relative;
    padding-right: 25px;

    &:after{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: 50% 0;
        position: absolute;
        right: 0px;
        top: .2em;
    }

    &[href $= pdf]{
        &:after{
            background-image: url(../images/common/icon_pdf.svg);
        }
    }

    &[href $= doc], &[href $= docx]{
        &:after{
            background-image: url(../images/common/icon_word.svg);
        }
    }

    &[href $= xls], &[href $= xlsx]{
        &:after{
            background-image: url(../images/common/icon_excel.svg);
        }
    }

    &.front{
        padding-left: 25px;
        padding-right: 0;
        &:after{
            left: 0px;
        }
    }
}
.iconBlank{
    @if $type == ""{
        display: inline-block;
        width: 17px;
        height: 13px;
        background:url("../images/common/icon_blank.svg") no-repeat 0 0;
        background-size: cover;
        vertical-align: middle;
        margin-left: 7px;
        position: relative;
        transform: translateY(-3px);
    }
}


.commonBtn{
    @if $type == ""{
        display: inline-block;
        text-align: center;
        position: relative;
        background-color: #fff;
        border-radius: 30px;
        color: $c_base;
        box-shadow: 0px 3px 6px 4px rgba(23,72,156,0.1);
    }
    @if $type == sp{
        font-size: 14px;
        padding: 15px 30px;
        width: 100%;
        .icon{
            width: 20px;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    @if $type == pc{
        font-size: 17px;
        padding: 15px 30px;
        min-width: 320px;
        transition: .3s;
        &:hover{
            background-color: $c_base;
            color: #fff;
        }
        &.sizeL{
            width: 60%;
        }
        .icon{
            width: 24px;
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    &.gradeBtn{
        @if $type == ""{
            background: linear-gradient(90deg, rgba(23,72,156,1) 0%, rgba(95,153,254,1) 50%, rgba(95,153,254,1) 100%);
            background-size: 200% 100%;
            color: #fff;
        }
        @if $type == pc{
            &:hover{
                background-position: 100% 0;
            }
        }
    }
    &.taikenBtn{
        @if $type == ""{
            background: url(../images/second/special_btn_bg.png) no-repeat left center $c_base;
            background-size: cover;
            color: #fff;
            font-weight: normal;
            position: relative;
            overflow: hidden;
            img{
                vertical-align: middle;
            }
        }
        @if $type == pc{
            &:hover{
                opacity: .7;
            }
        }
    }
}

.imgLine2{
    @if $type == sp{
        &.spflex{
            display: flex;
            li{
                margin: 0;
                width: calc(50% - 8px);
                &:nth-of-type(odd){
                    margin-right: 16px;
                }
            }
        }
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }

    li{
        text-align: center;

        @if $type == sp{
            margin-top: 20px;
        }
        @if $type == pc{
            width: calc(50% - 20px);
            &:nth-of-type(odd){
                margin-right: 40px;
            }
            &:nth-of-type(n + 3){
                margin-top: 40px;
            }
        }

        img{
            //width: 100%;
            max-width: 100%;
        }
    }

    &.small{
        @if $type == pc{
            width: 80%;
            margin-left:auto;
            margin-right:auto;
        }
    }
    .cap{
        display: block;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
}

.imgLine3{

    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }

    li{
        text-align: center;

        @if $type == sp{
            margin-top: 20px;
        }
        @if $type == pc{
            width: calc((100% / 3 ) - 17px);
            margin-right: 25px;
            &:nth-of-type(3n){
                margin-right: 0;
            }
            &:nth-of-type(n + 4){
                margin-top: 25px;
            }
        }

        img{
            max-width: 100%;
        }
    }
    .cap{
        display: block;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
}



.imgInP{
    @if $type == sp{
    }
    @if $type == pc{
        overflow: hidden;
    }

    .img{
        @if $type == sp{
            display: block;
            margin: 0 auto;
            padding-bottom: 20px;
        }
        @if $type == pc{
            max-width: 300px;

            img{
                width: 100%;
                height: auto;
            }
        }

        .cap{
            display: block;
            text-align: center;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .img.left{
        @if $type == sp{
        }
        @if $type == pc {
            float: left;
            margin-right: 40px;
        }
    }

    .img.right{
        @if $type == sp{
        }
        @if $type == pc{
            float: right;
            margin-left: 40px;
        }
    }

    &.sp_img_btm{
        @if $type == sp{
            display: flex;
            flex-wrap: wrap;

            .img{
                display: block;
                order: 2;
                margin: 20px auto 0;
                padding-bottom: 0;
            }

            .text{
                flex-basis: 100%;
                order: 1;
            }
        }
    }
}

.twoCol{

    @if $type == sp{

        .txt{

            *:nth-child(1){
                margin-top: 0;
            }

            & + .img{
                margin-top: 20px;
            }
        }

        .img{
            width: 100%;
            text-align: center;

            & + .txt{
                margin-top: 20px;
            }

            img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    @if $type == pc{
        display: flex;

        .txt{
            flex: 1;

            *:nth-child(1){
                margin-top: 0;
            }

            & + .img{
                margin-left: 40px;
            }
        }

        .img{
            max-width: 50%;

            & + .txt{
                margin-left: 40px;
            }

            img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }

    .cap{
        display: block;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
}

.colorBox{
 @include colorBox;
}

//テーブル
.commonTable{
    @if $type == ""{
        width: 100%;
        border-collapse: collapse;
        border-spacing: inherit;
        border-top: 1px solid $c_base;
        border-left: 1px solid $c_base;
        .notes{
            font-size: 13px;
        }
    }

    td.wFix,
    th.wFix{
        @if $type == sp{
            width: 120px;
        }
        @if $type == pc{
            width: 150px;
        }
    }

    th{
        @if $type == ""{
            font-weight: bold;
            border-bottom: 1px solid $c_base;
            border-right: 1px solid $c_base;
            background-color: $c_base;
            color: #fff;
            text-align: left;
            &.sub{
                background-color: #d1e2ff;
                color: $c_text;
            }
        }

        @if $type == sp{
            padding: 10px 20px;
        }
        @if $type == pc{
            padding: 10px 30px;
            font-size: 14px;
        }

        &.null{
            border-top: 1px solid $c_base;
            border-left: 1px solid $c_base;
            background-color: #fff;
        }
    }

    td{
        @if $type == ""{
            border-bottom: 1px solid $c_base;
            border-right: 1px solid $c_base;
            word-break: break-all;
            &.bdb{
                border-bottom: 1px solid #dde2eb;
            }
            &.bdr{
                border-right: 1px solid #dde2eb;
            }
            &.sub{
                background-color: #d1e2ff;
                color: $c_text;
            }
        }

        @if $type == sp{
            padding: 15px 20px;
        }
        @if $type == pc{
            padding: 15px 30px;
            font-size: 14px;
        }

        &.null{
            border-top: 1px solid $c_base;
            border-left: 1px solid $c_base;
        }
    }
    &.pdSmall{
        th{
            @if $type == sp {
                padding: 10px 10px;
            }
            @if $type == pc {
                padding: 10px 10px;
            }
        }
        td{
            @if $type == sp {
                padding: 10px 10px;
            }
            @if $type == pc {
                padding: 10px 10px;
            }
        }
    }
}

.scrollTable{
    @if $type == ""{
        overflow: auto;
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
    table{
        @if $type == sp{
            white-space: nowrap;
        }
    }
}

.scrollText{
    @if $type == sp {
      font-size: 12px;
      display: block;
      text-align: right;
      margin: 20px 0 0;
    }
    @if $type == pc {
        display: none;
    }
}

.scrollText + .scrollTable{
    @if $type == "" {
        margin-top: 0;
    }
}

.iframeWrap{
    @if $type == pc{
        max-width: 720px;
        margin: 0 auto;
    }
}
.iframeBox{
    position: relative;
    padding-bottom: 56.25%;

    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    @if $type == sp{
        margin-top: 20px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

/* common class */

.pointerNone{
    cursor: default;
    text-decoration: none;
}

.taC{text-align: center!important;}
.taL{text-align: left!important;}
.taR{text-align: right!important;}
.Center{margin: 0 auto!important;}
.ovh{overflow: hidden!important;}
.mw100{max-width: 100%!important;
    width: auto!important;}
.w100p{width: 100%!important;}

.mB0{margin-bottom: 0!important;}
.mB5{margin-bottom: 5px!important;}
.mB10{margin-bottom: 10px!important;}
.mB20{margin-bottom: 20px!important;}
.mB30{margin-bottom: 30px!important;}
.mB40{margin-bottom: 40px!important;}
.mB50{margin-bottom: 50px!important;}
.mB60{margin-bottom: 60px!important;}
.mB70{margin-bottom: 70px!important;}
.mB80{margin-bottom: 80px!important;}
.mT0{margin-top: 0!important;}
.mT5{margin-top: 5px!important;}
.mT10{margin-top: 10px!important;}
.mT20{margin-top: 20px!important;}
.mT30{margin-top: 30px!important;}
.mT40{margin-top: 40px!important;}
.mT50{margin-top: 50px!important;}
.mT60{margin-top: 60px!important;}
.mT70{margin-top: 70px!important;}
.mT80{margin-top: 80px!important;}
.mL0{margin-left: 0px!important;}
.mL5{margin-left: 5px!important;}
.mL10{margin-left: 10px!important;}
.mL20{margin-left: 20px!important;}
.mL30{margin-left: 30px!important;}
.mL40{margin-left: 40px!important;}
.mL50{margin-left: 50px!important;}
.mR0{margin-right: 0px!important;}
.mR5{margin-right: 5px!important;}
.mR10{margin-right: 10px!important;}
.mR20{margin-right: 20px!important;}
.mR30{margin-right: 30px!important;}
.mR40{margin-right: 40px!important;}
.mR50{margin-right: 50px!important;}
.size12{font-size: 12px !important;}
.size14{font-size: 14px !important;}
.size16{font-size: 16px!important;}
.size18{font-size: 18px!important;}
.size20{font-size: 20px!important;}

@if $type == sp{
    .mB0{margin-bottom: 0!important;}
    .mB5_sp{margin-bottom: 5px!important;}
    .mB10_sp{margin-bottom: 10px!important;}
    .mB20_sp{margin-bottom: 20px!important;}
    .mB30_sp{margin-bottom: 30px!important;}
    .mB40_sp{margin-bottom: 40px!important;}
    .mB50_sp{margin-bottom: 50px!important;}
    .mT0{margin-top: 0!important;}
    .mT5_sp{margin-top: 5px!important;}
    .mT10_sp{margin-top: 10px!important;}
    .mT20_sp{margin-top: 20px!important;}
    .mT30_sp{margin-top: 30px!important;}
    .mT40_sp{margin-top: 40px!important;}
    .mT50_sp{margin-top: 50px!important;}
    .mL0_sp{margin-left: 0px!important;}
    .mL5_sp{margin-left: 5px!important;}
    .mL10_sp{margin-left: 10px!important;}
    .mL20_sp{margin-left: 20px!important;}
    .mL30_sp{margin-left: 30px!important;}
    .mL40_sp{margin-left: 40px!important;}
    .mL50_sp{margin-left: 50px!important;}
    .mR0_sp{margin-right: 0px!important;}
    .mR5_sp{margin-right: 5px!important;}
    .mR10_sp{margin-right: 10px!important;}
    .mR20_sp{margin-right: 20px!important;}
    .mR30_sp{margin-right: 30px!important;}
    .mR40_sp{margin-right: 40px!important;}
    .mR50_sp{margin-right: 50px!important;}
    .size12_sp{font-size: 12px !important;}
    .size14_sp{font-size: 14px !important;}
    .size16_sp{font-size: 16px!important;}
    .size18_sp{font-size: 18px!important;}
    .size20_sp{font-size: 20px!important;}
    .w50p_sp{width: 50%!important;}
}
@if $type == pc{
    .mB0_pc{margin-bottom: 0!important;}
    .mB5_pc{margin-bottom: 5px!important;}
    .mB10_pc{margin-bottom: 10px!important;}
    .mB20_pc{margin-bottom: 20px!important;}
    .mB30_pc{margin-bottom: 30px!important;}
    .mB40_pc{margin-bottom: 40px!important;}
    .mB50_pc{margin-bottom: 50px!important;}
    .mB60_pc{margin-bottom: 60px!important;}
    .mB70_pc{margin-bottom: 70px!important;}
    .mB80_pc{margin-bottom: 80px!important;}
    .mT0_pc{margin-top: 0!important;}
    .mT5_pc{margin-top: 5px!important;}
    .mT10_pc{margin-top: 10px!important;}
    .mT20_pc{margin-top: 20px!important;}
    .mT30_pc{margin-top: 30px!important;}
    .mT40_pc{margin-top: 40px!important;}
    .mT50_pc{margin-top: 50px!important;}
    .mT60_pc{margin-top: 60px!important;}
    .mT70_pc{margin-top: 70px!important;}
    .mT80_pc{margin-top: 80px!important;}
    .mL0_pc{margin-left: 0px!important;}
    .mL5_pc{margin-left: 5px!important;}
    .mL10_pc{margin-left: 10px!important;}
    .mL20_pc{margin-left: 20px!important;}
    .mL30_pc{margin-left: 30px!important;}
    .mL40_pc{margin-left: 40px!important;}
    .mL50_pc{margin-left: 50px!important;}
    .mR0_pc{margin-right: 0px!important;}
    .mR5_pc{margin-right: 5px!important;}
    .mR10_pc{margin-right: 10px!important;}
    .mR20_pc{margin-right: 20px!important;}
    .mR30_pc{margin-right: 30px!important;}
    .mR40_pc{margin-right: 40px!important;}
    .mR50_pc{margin-right: 50px!important;}
    .size12_pc{font-size: 12px !important;}
    .size14_pc{font-size: 14px !important;}
    .size16_pc{font-size: 16px!important;}
    .size18_pc{font-size: 18px!important;}
    .size20_pc{font-size: 20px!important;}
}
.red{color: #de0000 !important;}
.blue{color: #8b99ff !important;}
.gray{color: #666 !important;}
.bold{font-weight: bold !important;}
.underline{text-decoration: underline!important;}
.green{color: #5cc49c!important;}
.pink{color: #ff9191!important;}
.bdb{border-bottom: 2px solid #004aa4;}
.fontL{font-size: 1.2em;}
.fontS{font-size: .8em;}

.floatL{float: left;}
.floatR{float: right;}

.preBox{
    background: #eff0f4;
    border: 1px solid $c_base;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;

    pre{
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align-last: auto;
    }
}
